import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FormControl from "@mui/material/FormControl";
import { _registerLeads, _getLeadsById } from "../../services/leads.service";
import { useNavigate } from "react-router-dom";

const ResumeUploadPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resume, setResume] = useState(null);
  const [error, setError] = useState("");
  const [leadsData, setLeadsData] = useState(null);

  const handleResumeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        setResume(file);
        setError("");
      } else {
        setError("Only PDF, DOC, and DOCX files are allowed.");
      }
    }
  };

  const handleSubmit = async () => {
    if (!email) {
      setError("Email is required.");
      return;
    }
    if (!resume) {
      setError("Resume is required.");
      return;
    }

    // Handle submission logic here
    console.log("Email:", email);
    console.log("Resume:", resume);
    const formData = new FormData();
    formData.append("email",email);
    formData.append("resume",resume);
    let lData = await _registerLeads(formData);
    setLeadsData(lData);
  };

  useEffect(()=>{
    if (leadsData) {
      navigate("/ai-interview/"+leadsData.l_id);
    }
  }, [leadsData]);

  return (
    <Container maxWidth="sm">
      <Paper
        elevation={6}
        sx={{
          padding: 4,
          marginTop: 8,
          borderRadius: 4,
          backgroundColor: "#f9f9f9",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            textAlign: "center",
            marginBottom: 4,
            fontWeight: "bold",
            color: "#1a237e", // Primary dark blue
          }}
        >
          Upload Your Resume
        </Typography>
        <Box component="form" noValidate autoComplete="off">
        <FormControl
          variant="outlined"
          sx={{ mt: 2, width: '100%', height: 50 }}
          required
        >
          <InputLabel htmlFor="outlined-email">Email</InputLabel>
          <OutlinedInput
            id="email"
            type="email"
            name="email"
            label="Email"
            autoComplete="email"
            autoFocus
            focused
            variant="outlined"
            onChange={(e)=>setEmail(e.target.value)}
            value={email}
          />
        </FormControl>
          <Button
            variant="outlined"
            component="label"
            startIcon={<UploadFileIcon />}
            fullWidth
            sx={{
              marginTop: 2,
              padding: "10px",
              color: "#1a237e", // Dark blue text
              borderColor: "#5c6bc0", // Soft blue border
              "&:hover": {
                backgroundColor: "#e3f2fd", // Light blue background on hover
              },
            }}
          >
            Upload Resume
            <input
              type="file"
              hidden
              onChange={handleResumeUpload}
              accept=".pdf,.doc,.docx"
            />
          </Button>
          {resume && (
            <Typography
              variant="body2"
              sx={{
                marginTop: 1,
                color: "#43a047", // Green for success
                fontStyle: "italic",
              }}
            >
              Uploaded File: {resume.name}
            </Typography>
          )}
          {error && (
            <Typography
              variant="body2"
              color="error"
              sx={{
                marginTop: 2,
              }}
            >
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            fullWidth
            sx={{
              marginTop: 3,
              padding: "10px",
              fontWeight: "bold",
              background:
                "linear-gradient(90deg, #3949ab 0%, #1a237e 100%)", // Gradient dark blue
              color: "#ffffff",
              "&:hover": {
                background:
                  "linear-gradient(90deg, #1a237e 0%, #3949ab 100%)", // Inverse gradient on hover
              },
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ResumeUploadPage;
