import axios from "axios";

export const _registerLeads = async (data) => {
  try {
    const url = `/leads/register`;
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const _getLeadsById = async (lead_id) => {
    try {
        const url = `/leads/get/${lead_id}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const _saveSampleData = async (data) => {
  try {
    const url = `/leads/save_sample_data`;
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const _startInterview = async (data) => {
  try {
    const url = `/leads/start-interview`;
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const _submitAnswer = async (data) => {
  try {
    const url = `/leads/submit-answer`;
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const _concludeInterview = async (data) => {
  try {
    const url = `/leads/conclude-interview`;
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const _getAiReport = async (id) => {
  try {
    const url = `/leads/ai-report/${id}`
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const _sendReport = async (data) => {
  try {
    const url = `/leads/send-report`
    const response = await axios.post(url, data);
    return response.data;
  } catch(error) {
    throw error;
  }
}