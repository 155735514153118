import React, { useState } from "react";
import { TextField, Button, Card, CardContent, Typography, Divider } from "@mui/material";

export const CreateOrganizationAdmin = () => {
  const [adminData, setAdminData] = useState({
    name: "",
    email: "",
    role: "",
    organization: "",
  });

  const handleChange = (e) => {
    setAdminData({ ...adminData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    // Add logic to save organization admin data
    // console.log("Organization Admin Data:", adminData);
    // Redirect or show a success message after adding
  };

  return (
    <Card raised sx={{ maxWidth: 600, margin: "0 auto", padding: 2 }}>
      <CardContent>
        <Typography variant="h5">Create Organization Admin</Typography>
        <Divider sx={{ marginY: 2 }} />
        <TextField
          fullWidth
          label="Admin Name"
          name="name"
          value={adminData.name}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Admin Email"
          name="email"
          value={adminData.email}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Role"
          name="role"
          value={adminData.role}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Organization"
          name="organization"
          value={adminData.organization}
          onChange={handleChange}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ marginTop: 2 }}
        >
          Submit
        </Button>
      </CardContent>
    </Card>
  );
};

export default CreateOrganizationAdmin;
