// FaceMetricCard.jsx
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

export const FaceMetricCard = ({ title, data, icon: Icon, color }) => (
  <Card>
    <CardContent>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Icon sx={{ color }} />
          <Typography variant="subtitle2">{title}</Typography>
        </Box>
        <Typography variant="h6" fontWeight="bold">{data.count}</Typography>
      </Box>
    </CardContent>
  </Card>
);
