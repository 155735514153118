import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Paper,
  Dialog,
  MenuItem,
  Select,
  Grid,
  LinearProgress,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { _getLeadsById } from "../../services/leads.service";
import OperatingSystemCheck from "../../components/OperatingSystemCheck";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import NetworkInfo from "../../components/candidate/NetworkInfo";
import { _saveSampleData, _sendReport } from "../../services/leads.service";
import AIInterview from "./AIInterview";
const MockInterview = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Extract 'id' from the URL
  const [leadsData, setLeadsData] = useState(null);
  const [confirm, setConfirm] = useState(false); // Changed to boolean for clarity
  const [deviceConfirmed, setDeviceConfirmed] = useState(false);
  const [speed, setSpeed] = useState("");
  const [videoRecorded, setVideoRecorded] = useState(false);
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState("");
  const [selectedAudioDevice, setSelectedAudioDevice] = useState("");
  const [microphoneQuality, setMicrophoneQuality] = useState(0); // Microphone quality as a percentage
  const [isMuted, setIsMuted] = useState(true);
  const [video, setVideo] = useState(null);
  const [videoDebug, setVideoDebug] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [openDeviceDialog, setOpenDeviceDialog] = useState(false);
  const [pictureTaken, setPictureTaken] = useState(false);
  const [file, setFile] = useState(null);
  const [ip, setIP] = useState(null);
  const [recordingText, setRecordingText] = useState(
    "Please record a sample video and speak something for sample"
  );
  const [recordingInProgress, setRecordingInProgress] = useState(false);

  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);

  const videoRef = useCallback(
    (node) => {
      if (node && selectedVideoDevice) {
        startVideo(selectedVideoDevice, node);
      }
    },
    [selectedVideoDevice]
  );
  const streamRef = useRef(null);
  const [email, setEmail] = useState("");
const [emailError, setEmailError] = useState(false);

const handleGetReport = async () => {
  const corporateEmailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu)$/;
  const isCorporateEmail = corporateEmailPattern.test(email) && !email.match(/@(gmail|yopmail|yahoo)\./);

  if (!isCorporateEmail) {
    setEmailError(true);
    return;
  }

  setEmailError(false);

  try {
    // Replace this with your API call logic
    const data = {id:id, email:email}
    await _sendReport(data);
    alert("Report has been sent to your email.");
    navigate("/upload-resume");
  } catch (error) {
    console.error("Error fetching report:", error);
    alert("An error occurred. Please try again later.");
  }
};
  useEffect(() => {
    const fetchData = async () => {
      try {
        const ldata = await _getLeadsById(id); // Fetch data from API
        setLeadsData(ldata);
      } catch (error) {
        console.error("Error fetching lead data:", error);
      }
    };
    fetchData();
  }, [id]);

  // Request permissions and detect video devices
  useEffect(() => {
    const getPermissionsAndDevices = async () => {
      try {
        // Request permissions for video and audio
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop()); // Stop the stream after permissions are granted

        // Enumerate video input devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setVideoDevices(videoDevices);
        const audioDevices = devices.filter((d) => d.kind === "audioinput");
        setAudioDevices(audioDevices);

        // // Automatically select the first device
        // if (videoDevices.length > 0) {
        //   selectedAudioDevice(videoDevices[0].deviceId);
        // }
      } catch (error) {
        console.error(
          "Error requesting permissions or fetching video devices:",
          error
        );
      }
    };

    getPermissionsAndDevices();
  }, []);

  const handleConfirm = () => {
    // Logic for confirming data
    console.log("Data confirmed:", leadsData);
    alert("Data has been confirmed!");
    setConfirm(true); // Update confirm state
  };

  const startRecording = async () => {
    try {
      if (!selectedVideoDevice || !selectedAudioDevice) {
        alert("Please select a video and audio device first.");
        return;
      }

      // Request audio and video streams
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: selectedVideoDevice } },
        audio: { deviceId: { exact: selectedAudioDevice } },
      });

      // Create a MediaRecorder
      const recorder = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });

      const chunks = []; // Local array to store recorded chunks

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      recorder.onstop = () => {
        // Combine recorded chunks into a single Blob
        const blob = new Blob(chunks, { type: "video/webm" });
        // Combine recorded chunks into a single Blob
        // Create a File object for further processing (e.g., upload)
        const videoFile = new File([blob], "recorded-video.webm", { type: "video/webm" });

        // Set the video file for uploading or other uses
        setVideo(videoFile); // Save the File object in state

        // Optionally, create an Object URL for playback (not necessary if you don't want to play locally)
        const videoUrl = URL.createObjectURL(blob);
        // console.log("Video URL for playback:", videoUrl); // For debugging, remove this line if not needed.
        setVideoDebug(videoUrl);


        setRecordedChunks([]); // Clear recorded chunks

        // Stop all tracks to free up resources
        stream.getTracks().forEach((track) => track.stop());
      };

      recorder.start();
      setMediaRecorder(recorder);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop(); // This triggers the `onstop` event defined in `startRecording`
      setMediaRecorder(null);
    }
  };


  const startVideo = async (deviceId, videoElement) => {
    try {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }

      // Enable only the video stream initially
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: deviceId } },
        audio: false, // Audio is disabled by default
      });

      videoElement.srcObject = videoStream;
      videoElement.play();
      streamRef.current = videoStream; // Save the video stream reference
    } catch (error) {
      console.error("Error starting video stream:", error);
    }
  };

  const handleAudioDeviceSelection = async (event) => {
    const audioDeviceId = event.target.value;
    setSelectedAudioDevice(audioDeviceId);

    // Start the audio stream and check quality
    startAudio(audioDeviceId);
  };

  // Check microphone quality and mute status
  const checkMicrophoneQuality = async (stream) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(stream);

    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    source.connect(analyser);

    audioContextRef.current = audioContext;
    analyserRef.current = analyser;
    dataArrayRef.current = dataArray;

    let silentFrames = 0; // Count of consecutive silent frames
    const silenceThreshold = 50; // Number of frames to confirm mute
    const silenceLevel = 80; // Adjusted threshold for silence detection

    const analyzeAudio = () => {
      analyser.getByteTimeDomainData(dataArray);
      const sum = dataArray.reduce((acc, value) => acc + Math.abs(value - 128), 0);
      const average = sum / dataArray.length;

      const quality = Math.min((average / 128) * 100, 100); // Calculate quality as a percentage
      setMicrophoneQuality(quality);
      // console.log("==========>",sum);

      // const isAudioSilent = sum < 80;
      // Increment or reset silentFrames based on audio input level
      if (sum < silenceLevel) {
        silentFrames++;
      } else {
        silentFrames = 0;
      }
      const isAudioSilent = silentFrames > silenceThreshold;
      if (stream.getAudioTracks()[0].muted) {
        setIsMuted(true);
      } else if (isAudioSilent) {
        setIsMuted(true);
      } else {
        setIsMuted(false);
      }

      // console.log(
      //   "Audio Input Level:",
      //   sum,
      //   "Silent Frames:",
      //   silentFrames,
      //   "Muted:",
      //   silentFrames > silenceThreshold
      // );

      // Check if the microphone is muted based on low or no audio input
      // const isAudioSilent = sum < 5; // Low threshold for audio input
      // setIsMuted(isAudioSilent);
      // console.log("====================>", stream.getAudioTracks());
      requestAnimationFrame(analyzeAudio);
    };

    analyzeAudio();
  };

  const startAudio = async (audioDeviceId) => {
    try {
      // Enable audio stream when a microphone is selected
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: audioDeviceId } },
      });

      // Check microphone quality for the selected audio device
      checkMicrophoneQuality(audioStream);
    } catch (error) {
      console.error("Error starting audio stream:", error);
    }
  };

  const confirmDeviceSelection = () => {
    if (selectedVideoDevice && selectedAudioDevice && !isMuted) {
      localStorage.setItem("selectedDeviceId", selectedVideoDevice);
      localStorage.setItem("selectedDeviceIdAudio", selectedAudioDevice);
      setDeviceConfirmed(true);
      setOpenDeviceDialog(false);
      takePicture();
      // Stop video stream to save resources
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    } else {
      alert("Please select all required perameter");
    }
  };

  const takePicture = () => {
    const canvas = document.createElement("canvas");
    const video = document.querySelector("#picture-capture-video");
    if (!video) {
      console.error("Video element not found for picture capture.");
      return;
    }
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const picture = canvas.toDataURL("image/png");
    console.log("Picture captured: ", picture);
    setPictureTaken(true);

    const byteString = atob(picture.split(",")[1]);
    const mimeString = picture.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    const file = new File([blob], "captured-image.png", { type: "image/png" });

    // Set the file to state for upload
    setFile(file);

  };

  useEffect(() => {
    if (file) {
      const saveFile = async () => {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("file", file);
        formData.append("ip", ip);
        formData.append("sample-video", video);
        await _saveSampleData(formData);
      }
      saveFile();
    }
  }, [file]);

  if (!leadsData) {
    return <Typography>Loading...</Typography>; // Show a loading indicator while data is fetched
  }

  return (
    <>
      {!confirm && leadsData && leadsData.l_status != "completed" && (
        <Container>
          <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
            <Typography variant="h4" gutterBottom>
              Resume Details
            </Typography>
            <Grid container spacing={2}>
              {/* Two-column layout */}
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Name:</strong> {leadsData.l_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Email:</strong> {leadsData.l_email}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Position:</strong> {leadsData.l_position}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Experience:</strong> {leadsData.l_exp} years
                </Typography>
              </Grid>

              {/* Full-width row for skills */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginTop: "10px" }}>
                  <strong>Skills:</strong>
                </Typography>
                <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
                  {JSON.parse(leadsData.l_skills).join(", ")}
                </Typography>
              </Grid>
            </Grid>
            <Box textAlign="center" marginTop="20px">
              <Button variant="contained" color="primary" onClick={handleConfirm}>
                Continue
              </Button>
            </Box>
          </Paper>
        </Container>
      )}

      {confirm && leadsData && leadsData.l_status !== "completed" && !deviceConfirmed && (
        <>
          {speed > 1 ? (
            !videoRecorded ? (
              <Grid container spacing={4}>
                {/* Left Section - Live Video Preview */}
                <Grid item xs={12} md={6}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      width: "100%",
                      border: "1px solid #ccc",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <video
                      ref={videoRef}
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      autoPlay
                      playsInline
                      muted
                    />
                  </Box>
                </Grid>

                {/* Right Section - Device Selection and Controls */}
                <Grid item xs={12} md={6}>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Typography variant="h5">
                      Select Devices (Current OS: <OperatingSystemCheck />)
                    </Typography>
                    <Select
                      value={selectedVideoDevice}
                      onChange={(e) => setSelectedVideoDevice(e.target.value)}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Select Camera
                      </MenuItem>
                      {videoDevices.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label || `Camera ${device.deviceId}`}
                        </MenuItem>
                      ))}
                    </Select>
                    <Select
                      value={selectedAudioDevice}
                      onChange={handleAudioDeviceSelection}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Select Microphone
                      </MenuItem>
                      {audioDevices.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label || `Microphone ${device.deviceId}`}
                        </MenuItem>
                      ))}
                    </Select>
                    <Box>
                      <Typography variant="subtitle1">
                        Microphone Status:{" "}
                        {isMuted ? (
                          <MicOffIcon color="error" />
                        ) : (
                          <>
                            <MicIcon color="primary" />
                            <LinearProgress
                              variant="determinate"
                              value={microphoneQuality}
                              style={{ height: "10px", borderRadius: "5px" }}
                            />
                          </>
                        )}
                      </Typography>
                    </Box>
                    {!recordingInProgress ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          startRecording();
                          setRecordingInProgress(true);
                        }}
                        fullWidth
                      >
                        Record a Sample Video
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          stopRecording();
                          setRecordingInProgress(false);
                          setVideoRecorded(true);
                        }}
                        fullWidth
                      >
                        Stop Recording
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={4} justifyContent="center" alignItems="center">
                {/* Message Section */}
                <Grid item xs={12} md={6}>
                  <Box display="flex" flexDirection="column" alignItems="center" gap="20px">
                    <Typography variant="subtitle1" color="textSecondary">
                      Video recording completed. Please review your video.
                    </Typography>
                    <Button variant="contained" color="primary" onClick={confirmDeviceSelection}>
                      Confirm and Proceed
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setVideoRecorded(false);
                        setRecordedChunks([]);
                        setMediaRecorder(null);
                      }}
                    >
                      Re-record Video
                    </Button>
                  </Box>
                </Grid>

                {/* Video Preview Section */}
                <Grid item xs={12} md={6}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      width: "100%",
                      border: "1px solid #ccc",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    {videoDebug && (
                      <video
                        id="picture-capture-video"
                        controls
                        style={{
                          width: "100%",
                          maxWidth: "600px",
                          height: "auto",
                        }}
                        src={videoDebug}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            )
          ) : (
            <Typography color="error">
              Poor network. Please ensure a stable and high-quality network connection.
            </Typography>
          )}
          <NetworkInfo speed={speed} setSpeed={setSpeed} />
        </>
      )}

      {confirm && leadsData && leadsData.l_status !== "completed" && deviceConfirmed && (
        <AIInterview
          userId={leadsData.l_id}
          userConsent={true}
          InterViewStage={leadsData.l_status}
          interViewThreadId=""
          streamRef={streamRef}
        />
      )}

      {leadsData && leadsData.l_status === "completed" && leadsData.l_report_status !== "opened" && (
        <Container>
          <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
            <Typography variant="h4" gutterBottom>
              Your AI Interview is Complete!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Thank you for completing the AI interview. You can now get your report.
            </Typography>
            <Box mt={3}>
              <Typography variant="h6">Validate Your Email</Typography>
              <Box display="flex" flexDirection="column" gap="15px" mt={2}>
                <input
                  type="email"
                  placeholder="Enter your corporate email"
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                    maxWidth: "400px",
                  }}
                  onChange={(e) => setEmail(e.target.value)} // Add email state
                />
                {emailError && (
                  <Typography color="error" variant="body2">
                    Please enter a valid corporate email (e.g., @company.com).
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGetReport}
                  disabled={!email || emailError}
                >
                  Get Report
                </Button>
              </Box>
            </Box>
          </Paper>
        </Container>
      )}
      {leadsData && leadsData.l_status === "completed" && leadsData.l_report_status === "opened" &&(
        <Container>
          <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
          <Typography variant="h6">
            Your AI interview demo is completed and interview report sent to you successfully!! Our team will reach you soon.
          </Typography>
          </Paper>
        </Container>
      )}

    </>
  );
};

export default MockInterview;
