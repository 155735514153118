import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import en from './locales/en.json';
// import fr from './locales/fr.json';
import axios from 'axios';

let BASE_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;
let orgName = localStorage.getItem('org_name');
let API_URL = '';
if (orgName) {
    API_URL = `${BASE_URL}/${orgName}/api/v1`
} else {
    API_URL = `${BASE_URL}/api/v1`
}
const fetchTranslations = async (orgId) => {
    try {

        const response = await axios.get(`${API_URL}/org_labels/${orgId}`);
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching translations:', error);
        return {};
    }
};

const updateI18nResources = (data) => {
    i18n.addResources('en', 'translation', data); // Assuming default language is 'en'
};

// i18n
// .use(initReactI18next).init({
//     resources: {
//         en: {
//             translation: en
//         },
//         fr: {
//             translation: fr
//         }
//     },
//     lng: 'en', // default language
//     fallbackLng: 'en', // fallback language
//     interpolation: {
//         escapeValue: false 
//     }
// });

// export default i18n;

const initializeI18n = async (orgId) => {
    const translations = await fetchTranslations(orgId);
    // updateI18nResources(translations);
    i18n
        .use(initReactI18next)
        .init({
            resources: {
                en: {
                    translation: translations, // Assuming the API returns translations for the default language
                },
                // You can add more languages here if your API supports them
            },
            lng: 'en', // default language
            fallbackLng: 'en', // fallback language
            interpolation: {
                escapeValue: false,
            },
        });
    return i18n;
};

export default initializeI18n;
