import axios from "axios";

export const _getCreditRecharges = async (page, size) => {
  try {
    const url = `/credit-recharges?page=${page}&pageSize=${size}`;
    
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getCreditRechargeById = async (id) => {
  try {
    const url = `/credit-recharge/${id}`;
    
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _createCreditRecharge = async (data) => {
  try {
    const url = `/credit-recharge`;
    
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _updateCreditRecharge = async (id, data) => {
  try {
    const url = `/credit-recharge/${id}`;
    
    const response = await axios.put(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _deleteCreditRecharge = async (id) => {
  try {
    const url = `/credit-recharge/${id}`;
    
    const response = await axios.delete(url);
    return response;
  } catch (error) {
    throw error;
  }
};