// ViolationTypeCard.jsx
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

export const ViolationTypeCard = ({
  title,
  count,
  icon: Icon,
  color
}) => (
  <Card>
    <CardContent>
      <Box display="flex" alignItems="center" gap={1}>
        <Icon sx={{ color }} />
        <Typography variant="subtitle2">{title}</Typography>
      </Box>
      <Typography variant="h5" fontWeight="bold" mt={1}>
        {count}
      </Typography>
    </CardContent>
  </Card>
);