import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Stack,
  Divider,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { _createCreditCharge, _updateCreditCharge } from "../../services/credit_charges.service";
import { getAllOrgOrgs } from "../../services/admin.service";


const CreditChargeForm = ({ initialData = {}, isEdit = false }) => {
  const navigate = useNavigate();
  const [orgData, setOrgData] = useState([]);
  const [formData, setFormData] = useState({
    cc_org_id: initialData.cc_org_id || "",
    cc_jd: initialData.cc_jd || 0,
    cc_cv_parsing: initialData.cc_cv_parsing || 0,
    cc_mcq: initialData.cc_mcq || 0,
    cc_code: initialData.cc_code || 0,
    cc_interview: initialData.cc_interview || 0,
    cc_report: initialData.cc_report || 0,
    cc_audio_to_text: initialData.cc_audio_to_text || 0,
    cc_ip: initialData.cc_ip || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEdit) {
        await _updateCreditCharge(initialData.cc_id, formData);
      } else {
        await _createCreditCharge(formData);
      }
      navigate("/admin/credit-charges");
    } catch (error) {
      console.error("Error saving credit charge:", error);
    }
  };

  useEffect(() => {
      const fetchOrgData = async () => {
        try {
          const oData = await getAllOrgOrgs();
          setOrgData(oData);
        } catch (error) {
          console.error("Error fetching organization data:", error);
        }
      };
      fetchOrgData();
    }, []);

  return (
    <Card raised sx={{ maxWidth: 600, margin: "auto" }}>
      <CardContent>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          {isEdit ? "Edit Credit Charge" : "Add Credit Charge"}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {/* <TextField
              label="Organization ID"
              name="cc_org_id"
              value={formData.cc_org_id}
              onChange={handleChange}
              fullWidth
              required
            /> */}
            <FormControl fullWidth>
                <InputLabel>Select Organization</InputLabel>
                <Select
                  label="Select Organization"
                  name="cc_org_id"
                  value={formData.cc_org_id}
                  onChange={handleChange}
                  required
                >
                  {orgData.map((item) => (
                    <MenuItem key={item.o_id} value={item.o_id}>
                      {item.o_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            <TextField
              label="JD Parsing"
              name="cc_jd"
              type="number"
              value={formData.cc_jd}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="CV Parsing"
              name="cc_cv_parsing"
              type="number"
              value={formData.cc_cv_parsing}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="MCQ"
              name="cc_mcq"
              type="number"
              value={formData.cc_mcq}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Code"
              name="cc_code"
              type="number"
              value={formData.cc_code}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Interview"
              name="cc_interview"
              type="number"
              value={formData.cc_interview}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Report"
              name="cc_report"
              type="number"
              value={formData.cc_report}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Audio to Text"
              name="cc_audio_to_text"
              type="number"
              value={formData.cc_audio_to_text}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="IP Address"
              name="cc_ip"
              value={formData.cc_ip}
              onChange={handleChange}
              fullWidth
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/admin/credit-charges")}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {isEdit ? "Update" : "Create"}
              </Button>
            </Stack>
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreditChargeForm;
