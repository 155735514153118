/* eslint-disable array-callback-return */
import { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Card,
  Divider,
  IconButton,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { uploadCVs, uploadCsV, getJobsAll, resetCvsJob } from "../../redux/actions/jobs";
import { getModelNameWithJobId } from "../../redux/actions/batches";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { io } from "socket.io-client";
import Badge from "@mui/material/Badge";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ProgressBarComponent from "../../components/ProgressBar";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

let BASE_URL =
  localStorage.getItem("REACT_APP_API_URL") || process.env.REACT_APP_API_URL;
let orgName = localStorage.getItem("org_name");
let API_URL = "";
if (orgName) {
  API_URL = `${BASE_URL}/${orgName}`;
} else {
  API_URL = `${BASE_URL}`;
}
const socket = io(BASE_URL, {
  path: `/${orgName}/socket.io`, // Adjust the path if necessary
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CreateBatch = ({
  uploadCVs,
  uploadCsV,
  resetCvsJob,
  getJobsAll,
  allJobs,
  cvsUploaded,
  getModelNameWithJobId,
  modelNameOfJobId,
}) => {
  const navigate = useNavigate();
  const { jd_id } = useParams();

  const [anchorElAct, setAnchorElAct] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [activities, setActivities] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);
  const [countdown, setCountdown] = useState(10); // Added countdown state
  const [redirecting, setRedirecting] = useState(false); // Added redirecting state
  // const [selectedOption, setSelectedOption] = useState("Baali");
  const [selectedModel, setSelectedModel] = useState("");
  const [cvsValue, setCvsValue] = useState({});

  useEffect(() => {
    setJobId(jd_id);
  }, [jd_id]);

  useEffect(() => {
    getModelName(jd_id);
  }, [selectedModel]);

  const getModelName = async (id) => {
    await getModelNameWithJobId(id);
    setSelectedModel(modelNameOfJobId?.jb_model_name || "Gemini");
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.from(e.target.files);
    if (chosenFiles.length > 200) {
          // alert("You can select up to 200 files at a time.");
          toast.error("You can select up to 200 files at a time.");
          return;
    }
    handleUploadFiles(chosenFiles);
  };

  const onSubmitCVs = async () => {
    if (jobId) {
      let reqBody = new FormData();
      uploadedFiles.forEach((file) => {
        reqBody.append("files", file, file.name);
      });
      reqBody.append("model_name", modelNameOfJobId?.jb_model_name);
      await uploadCVs(reqBody, jobId);
      // setCvsValue(cvsUploaded);
      setTotalSteps(uploadedFiles.length);
      setUploadedFiles([]);
    }
  };

  const removeCV = (index) => () => {
    const files = [...uploadedFiles];
    files.splice(index, 1);
    setUploadedFiles(files);
    setFileLimit(false);
  };

  useEffect(() => {
    if (cvsUploaded && cvsUploaded.batch_id) {
      // console.log("cvsUploaded", cvsUploaded.type);
      const batchId = cvsUploaded.batch_id;
      const type = cvsUploaded.type || "gemini";
      // resetCvsJob();
      navigate(`/hr/batches/batch-detail/${batchId}`, { state: { type } });
    }
  }, [cvsUploaded, navigate]);
  

  // useEffect(() => {
  //   if (totalSteps > 0 && currentStep >= totalSteps) {
  //     setRedirecting(true);
  //     const interval = setInterval(() => {
  //       setCountdown((prevCountdown) => prevCountdown - 1);
  //     }, 1000);

  //     setTimeout(() => {
  //       clearInterval(interval);
  //       navigate(`/hr/batches/batch-detail/${cvsUploaded.batch_id}`);
  //     }, 10000);
  //   }
  // }, [currentStep, totalSteps, cvsUploaded.batch_id, navigate]);

  // useEffect(() => {
  //   if (currentStep < totalSteps) {
  //     setTimeout(() => {
  //       setCurrentStep((prevStep) => prevStep + 1);
  //     }, 6000);
  //   }
  // }, [currentStep, totalSteps]);

  // useEffect(() => {
  //   socket.on("to_client", (data) => {
  //     if (data.progress === 100) {
  //       setActivities((prevActivities) =>
  //         prevActivities.filter((act) => act.jd_id !== data.jd_id)
  //       );
  //     } else {
  //       setActivities((prevActivities) => {
  //         const activityExists = prevActivities.some(
  //           (act) => act.jd_id === data.jd_id
  //         );
  //         if (activityExists) {
  //           return prevActivities.map((act) =>
  //             act.jd_id === data.jd_id ? data : act
  //           );
  //         } else {
  //           return [...prevActivities, data];
  //         }
  //       });
  //     }
  //   });
  // }, []);

  const handleMenuAct = (event) => {
    setAnchorElAct(event.currentTarget);
  };

  const handleCloseAct = () => {
    setAnchorElAct(null);
  };


  // const handleBulkUpload = (file) => {
  //   const reader = new FileReader();

  //   reader.onload = async (event) => {
  //     const data = new Uint8Array(event.target.result);
  //     const workbook = XLSX.read(data, { type: "array" });

  //     // Utility function to rename keys and handle unique IDs
  //     const renameKeys = (data, keyMapping, addUuidKey = false) => {
  //       return data.map((row) => {
  //         return Object.keys(row).reduce((acc, key) => {
  //           const newKey = keyMapping[key] || key; // Use mapped key or keep original
  //           acc[newKey] = key === "S.No" && addUuidKey ? uuidv4() : row[key];
  //           return acc;
  //         }, {});
  //       });
  //     };

  //     // Transform MCQ data into required structure

  //     // Key mappings for Coding and AI Interview sheets
  //     const commonKeyMapping = {
  //       "S.No": "apct_id",
  //       Name: "apct_name",
  //       Email: "apct_email",
  //       Mobile: "apct_mobile",
  //       Experience: "apct_exp",
  //     };

  //     // Parse each sheet
  //     const applicantSheet = workbook.Sheets["Sheet 1"];

  //     // Process Coding sheet
  //     const applicantData = applicantSheet
  //       ? renameKeys(
  //         XLSX.utils.sheet_to_json(applicantSheet),
  //         commonKeyMapping,
  //         true
  //       )
  //       : [];

  //     // const updatedApplicantData = applicantData.map((data) => ({
  //     //   ...data,
  //     //   apct_exp: parseExperience(data.apct_exp)
  //     // }));

  //     if (jobId) {
  //       const reqBody = {
  //         model_name: "CSV",
  //         applicantData: applicantData,
  //       };
  //       await uploadCsV(reqBody, jobId);
  //       // setTotalSteps(uploadedFiles.length);
  //       setUploadedFiles([]);
  //     }
  //   };

  //   reader.onerror = (error) => {
  //     console.error("File reading error:", error);
  //   };

  //   reader.readAsArrayBuffer(file);
  // };

  const handleBulkUpload = (file) => {
    const reader = new FileReader();
  
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const fileType = file.name.split('.').pop().toLowerCase();
  
      let workbook;
  
      // Determine file type and process accordingly
      if (fileType === "xlsx") {
        // Read the workbook and rename the sheet to "Sample_Applicant"
        const originalWorkbook = XLSX.read(data, { type: "array" });
        const originalSheet = originalWorkbook.Sheets[originalWorkbook.SheetNames[0]]; // Assuming first sheet is the target
        workbook = {
          Sheets: { "Sample_Applicant": originalSheet },
          SheetNames: ["Sample_Applicant"],
        };
      } else if (fileType === "csv") {
        // Convert CSV to JSON and set sheet name to "Sample_Applicant"
        const csvText = new TextDecoder("utf-8").decode(data);
        const csvRows = XLSX.utils.sheet_to_json(
          XLSX.read(csvText, { type: "string" }).Sheets["Sheet1"]
        );
        workbook = { Sheets: { "Sample_Applicant": XLSX.utils.json_to_sheet(csvRows) }, SheetNames: ["Sample_Applicant"] };
      } else {
        console.error("Unsupported file type:", fileType);
        return;
      }
  
      // Utility function to rename keys and handle unique IDs
      const renameKeys = (data, keyMapping, addUuidKey = false) => {
        return data.map((row) => {
          return Object.keys(row).reduce((acc, key) => {
            const newKey = keyMapping[key] || key; // Use mapped key or keep original
            acc[newKey] = key === "S.No" && addUuidKey ? uuidv4() : row[key];
            return acc;
          }, {});
        });
      };
  
      // Key mappings for common columns
      const commonKeyMapping = {
        "S.No": "apct_id",
        Name: "apct_name",
        Email: "apct_email",
        Mobile: "apct_mobile",
        Experience: "apct_exp",
      };
  
      // Parse and transform data from the "Sample_Applicant" sheet
      const applicantSheet = workbook.Sheets["Sample_Applicant"];
      const applicantData = applicantSheet
        ? renameKeys(
            XLSX.utils.sheet_to_json(applicantSheet),
            commonKeyMapping,
            true
          )
        : [];
  
      // Prepare and upload the data
      if (jobId) {
        const reqBody = {
          model_name: "CSV",
          applicantData: applicantData,
        };
  
        try {
          await uploadCsV(reqBody, jobId);
          // setCvsValue(cvsUploaded);
          setUploadedFiles([]); // Clear uploaded files
        } catch (err) {
          console.error("Error uploading CSV data:", err);
        }
      }
    };
  
    reader.onerror = (error) => {
      console.error("File reading error:", error);
    };
  
    reader.readAsArrayBuffer(file);
  };
  
  

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      handleBulkUpload(selectedFile); // Call bulk upload function
    }
  };

  // const handleDownloadSample = () => {
  //   // Define data for each sheet
  //   const sheetData = [["S.No", "Name", "Email", "Mobile", "Experience"]];

  //   // Create a workbook
  //   const workbook = XLSX.utils.book_new();

  //   // Add sheets to the workbook
  //   const sheet = XLSX.utils.aoa_to_sheet(sheetData);

  //   // Append sheets to the workbook
  //   XLSX.utils.book_append_sheet(workbook, sheet, "Sheet 1");

  //   // Write the workbook and trigger the download
  //   XLSX.writeFile(workbook, "Sample_Applicant.xlsx");
  // };

  const handleDownloadSample = () => {
    // Define data for CSV
    const sheetData = [["S.No", "Name", "Email", "Mobile", "Experience"]];
  
    // Convert data to a worksheet
    const sheet = XLSX.utils.aoa_to_sheet(sheetData);
  
    // Convert worksheet to CSV format
    const csvOutput = XLSX.utils.sheet_to_csv(sheet);
  
    // Create a Blob for the CSV data
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });
  
    // Create a download link
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = "Sample_Applicant.csv";
  
    // Trigger the download
    link.click();
  
    // Clean up
    URL.revokeObjectURL(url);
  };
  

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  return (
    <Card xs={12} sx={{ mt: 1, minHeight: "50%" }}>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant={"h5"} sx={{ ml: 2, mt: 2 }}>
            Create Batch
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Analysing Module
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedModel}
              label="Analysing Module"
              // onChange={handleOptionChange}
              disabled={true}
            >
              <MenuItem value={"chat_gpt"}>Chat GPT</MenuItem>
              <MenuItem value={"Baali"}>Baali</MenuItem>
              <MenuItem value={"gemini"}>Gemini</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={12} textAlign="center">
          <Button
            color="primary"
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload CVs
            <VisuallyHiddenInput
              type="file"
              onChange={handleFileEvent}
              multiple
              disabled={fileLimit}
              accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
          </Button>
          {localStorage.getItem("org_name") === "org_girikon_india" && (<><Button
            variant="outlined"
            sx={{ ml: 2, mr: 2 }}
            onClick={handleDownloadSample}
          >
            {" "}
            Download Sample
          </Button>
            <input
              type="file"
              accept=".csv"
              onChange={onFileChange}
              style={{ display: "none" }}
              id="bulk-upload-input"
            />
            <label htmlFor="bulk-upload-input">
              <Button variant="contained" color="primary" component="span">
                Bulk Upload CSV
              </Button>
            </label></>)}


          <br />
          <small>
            <InfoIcon /> <i>Only PDF & Docx files are supported!</i>
          </small>
        </Grid>
        <Grid item xs={12} sm={12} textAlign="center">
          {uploadedFiles.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">SN</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadedFiles.map((file, index) => (
                    <TableRow key={file.name}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{file.name}</TableCell>
                      <TableCell>
                        <IconButton onClick={removeCV(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        {uploadedFiles.length > 0 && (
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} textAlign="end">
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitCVs}
                disabled={!jobId}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        )}

        {/* {redirecting && (
          <Grid item xs={12} textAlign="center" sx={{ mt: 4 }}>
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2 }}>
              You are redirecting in {countdown} seconds...
            </Typography>
          </Grid>
        )} */}
      </Grid>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  allJobs: state.jobs.allJobs,
  cvsUploaded: state.jobs.cvsUploaded,
  modelNameOfJobId: state.batches.modelNameOfJobId,
});

const mapDispatchToProps = {
  uploadCVs,
  uploadCsV,
  getJobsAll,
  resetCvsJob,
  getModelNameWithJobId,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBatch);
