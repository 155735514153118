import axios from "axios";

export const _getallAvatar = async () => {
  try {
    const url = `/avatar/get-all`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const _updateAvatar = async (body) => {
  try {
    const url = `/avatar/update-avatar`;
    const response = await axios.put(url,body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAvatar = async () => {
  try {
    const url = `/avatar/get-avatar`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const _uploadAvatar = async (data) => {
  try {
    const url = `/avatar/upload-avatar`;
    const response = await axios.post(url,data)
  } catch (error) {
    throw error;
  }
}