import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { useParams } from "react-router-dom";

import Copyright from "../../components/Copyright";
import Logo from "../../components/Logo";

import { connect } from "react-redux";
import { loginUser } from "../../redux/actions/auth";
import { loginCandidate } from "../../redux/actions/candidate";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { validateReCaptcha } from "../../services/auth.service";
import SignInIMage from "../../assets/sigin.jpg";

const defaultTheme = createTheme();

const SignIn = ({ loginUser }) => {
  const { apct_id, t } = useParams();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (apct_id && t) {
      window.localStorage.removeItem("rememberData");
      localStorage.setItem("apct_id", apct_id);
      localStorage.setItem("apct_t", t);
    }
  }, [apct_id, t]);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    org: "",
    reCaptchaToken: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const storedRememberData = JSON.parse(
      window.localStorage.getItem("rememberData")
    );
    if (storedRememberData) {
      setLoginData(storedRememberData);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (rememberMe) {
      window.localStorage.setItem("rememberData", JSON.stringify(loginData));
    }
  }, [rememberMe, loginData]);

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const captchaToken = await executeRecaptcha("login");
      const dataWithCaptcha = {
        ...loginData,
        reCaptchaToken: captchaToken,
      };

      await loginUser(dataWithCaptcha);

      // if (apct_id && apct_id) {
      //   await loginCandidate(loginData);
      //   navigate(`/candidate/ai-interview`, { replace: true });
      // } else {

      // navigate("/", { replace: true });
      // }
    } catch (error) {
      // console.log(error);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {
    setShowPassword(true);
  };

  return (
    <Grid container item xs={12} sm={12}>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // alignContent: "center",
          minHeight: "100vh",
          justifyContent: "center",
          background: "white",
        }}
      >
        <Logo />
        <Typography variant="h5" sx={{ mt: 2 }}>
          Sign In
        </Typography>
        <FormControl
          variant="outlined"
          sx={{ mt: 2, width: 350, height: 50 }}
          required
        >
          <InputLabel htmlFor="outlined-email">Email</InputLabel>

          <OutlinedInput
            id="email"
            type="email"
            name="email"
            label="Email"
            autoComplete="email"
            autoFocus
            focused
            variant="outlined"
            value={loginData.email}
            onChange={handleLoginChange}
          />
        </FormControl>

        {/* <FormControl
          variant="outlined"
          sx={{ mt: 2, width: 350, height: 50 }}
          required
        >
          <InputLabel htmlFor="outlined-text">Company</InputLabel>
          <OutlinedInput
            required
            fullWidth
            id="text"
            label="Company"
            name="org"
            autoComplete="org"
            focused
            value={loginData.org}
            onChange={handleLoginChange}
          />
        </FormControl> */}
        <FormControl
          variant="outlined"
          sx={{ mt: 2, width: 350, height: 50 }}
          required
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword ? "text" : "password"}
            name="password"
            label="Password"
            autoComplete="current-password"
            focused
            value={loginData.password}
            onChange={handleLoginChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Grid sx={{ width: 350 }}>
          <FormControlLabel
            control={
              <Checkbox
                value={rememberMe}
                checked={rememberMe && rememberMe}
                color="primary"
                onClick={() => setRememberMe(!rememberMe)}
              />
            }
            label="Remember me"
          />
        </Grid>
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2, width: 350 }}
          onClick={(e) => handleSubmit(e)}
          onKeyDown={(e) => handleSubmit(e)}
          disabled={!loginData.email || !loginData.password}
        >
          Sign In
        </Button>
        <Grid container sx={{ width: 350 }}>
          <Grid item xs>
            <Link href="/forgot-password" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          {/* <Grid item>
            <Link href="/signup" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid> */}
          {/* <Grid item>
            <Link href="/upload-resume" variant="body2">
              Try Now
            </Link>
          </Grid> */}
        </Grid>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Grid>
      <Grid item xs={6} sm={6}>
        <div className="container-fluid homepage-bgimage"></div>
      </Grid>
    </Grid>
  );
};

const SignInReCaptcha = ({ loginUser }) => {
  const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_API_KEY;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
      <SignIn loginUser={loginUser} />
    </GoogleReCaptchaProvider>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isCandLoggedIn: state.candidate.isCandLoggedIn,
  candidateData: state.candidate.candidateData,
});

const mapDispatchToProps = { loginUser, loginCandidate };

export default connect(mapStateToProps, mapDispatchToProps)(SignInReCaptcha);
