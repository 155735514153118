// index.jsx
import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Container,
} from "@mui/material";
import {
  Person,
  Videocam,
  Message,
  PhoneAndroid,
  Group,
  RemoveRedEye,
} from "@mui/icons-material";
import { FaceMetricCard } from "./FaceMetricCard";
import { ViolationTypeCard } from "./ViolationTypeCard";
import { QuestionCard } from "./QuestionCard";

const ProctorReport = ({ studentData }) => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  return (
    // <Container maxWidth="lg" sx={{ py: 2 }}>
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} md={12}>
        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Face Detection Analysis
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <FaceMetricCard
                  title="No Face Detected"
                  data={studentData.faceDetectionMetrics.noFace}
                  icon={Person}
                  color="#d32f2f"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FaceMetricCard
                  title="Multiple Faces"
                  data={studentData.faceDetectionMetrics.multipleFaces}
                  icon={Group}
                  color="#ed6c02"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FaceMetricCard
                  title="Looking Away"
                  data={studentData.faceDetectionMetrics.lookingAway}
                  icon={RemoveRedEye}
                  color="#ed6c02"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FaceMetricCard
                  title="Cell Phone Violations"
                  data={studentData.faceDetectionMetrics.cellPhoneViolations}
                  icon={PhoneAndroid}
                  color="#ed6c02"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={12}>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={6} md={4}>
            <ViolationTypeCard
              title="Face Violations"
              // count={studentData.questions.reduce(
              //   (acc, q) => acc + q.faceViolations.length,
              //   0
              // )}
              count={studentData.proctoringQuestionSummary.faceViolation}
              icon={Person}
              color="#1976d2"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <ViolationTypeCard
              title="Lip Sync Violations"
              // count={studentData.questions.reduce(
              //   (acc, q) => acc + q.lipSyncViolations.length,
              //   0
              // )}
              count={studentData.proctoringQuestionSummary.Lip_Sync_Violation}
              icon={Videocam}
              color="#9c27b0"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <ViolationTypeCard
              title="Voice Violations"
              // count={studentData.questions.reduce(
              //   (acc, q) => acc + q.voiceViolations.length,
              //   0
              // )}
              count={studentData.proctoringQuestionSummary.Voice_Violation}
              icon={Message}
              color="#ed6c02"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box sx={{ "& > :not(:last-child)": { mb: 2 } }}>
          {studentData.questions.map((question) => (
            <QuestionCard
              key={question.number}
              question={question}
              isExpanded={expandedQuestion === question.number}
              onToggle={() =>
                setExpandedQuestion(
                  expandedQuestion === question.number ? null : question.number
                )
              }
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProctorReport;
