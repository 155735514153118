import React, { useState } from 'react';
import { Modal, Box, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Modal style
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '8px',
  outline: 'none',
};

const ViolationSliderModal = ({ images, open, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // console.log('open Status', open);

  // Slider navigation handlers
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <>
      {/* Only render modal if images array has length > 0 */}
      {images.length > 0 && (
        <Modal open={open} onClose={onClose} aria-labelledby="image-slider-modal">
          <Box sx={modalStyle}>
            {/* Modal Header */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6" component="h2">
                Violation Images
              </Typography>
              <IconButton onClick={onClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Image Display */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="relative"
              mb={2}
            >
              <img
                src={images[currentIndex]}
                alt={`Slide ${currentIndex + 1}`}
                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
              />
            </Box>

            {/* Slider Controls */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Button variant="contained" color="primary" onClick={handlePrev}>
                Previous
              </Button>
              <Typography variant="body1">
                {currentIndex + 1} / {images.length}
              </Typography>
              <Button variant="contained" color="primary" onClick={handleNext}>
                Next
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ViolationSliderModal;
