import React, { Suspense } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls, Html } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

const ObjModel = () => {
  const obj = useLoader(OBJLoader, '/pipe_00.obj'); 
  return <primitive object={obj} scale={[2, 1, 1]} position={[0, -2, 0]} />;
};

const ObjViewer = () => {
  return (
    <div style={{ width: '100%', height: '400px' }}>
      <Canvas style={{ width: '100%', height: '100%', background: 'black' }}>
        <Suspense
          fallback={
            <Html center>
              <div>Loading model...</div>
            </Html>
          }
        >
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} />
          <ObjModel />
          <OrbitControls />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default ObjViewer;
