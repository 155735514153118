import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { authUser } from "../../redux/actions/auth";
import { setSkillData } from "../../redux/actions/skills";
import {
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Button,
  Box,
  Divider,
} from "@mui/material";

export const EvaluateSkills = ({ auth, authUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [skillList, setSkillList] = useState([]);
  const [se_id, setse_id] = useState("");
  const [name, setname] = useState("");
  const [overallExp, setOverallExp] = useState("");
  const [relevantExp, setRelevantExp] = useState("");
  const [evaluateSkills, setEvaluateSkills] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    authUser();
  }, []);

  useEffect(() => {
    // console.log("Auth=====>", auth);
    if (auth) {
      setse_id(auth?._selfEval[0]?.se_id);
      setname(auth?._selfEval[0]?.se_name);
      setOverallExp(auth?._selfEval[0]?.se_overall_experience);
      setRelevantExp(auth?._selfEval[0]?.se_relevant_experience);
    }
    if (auth?._skills?.length) {
      // console.log(auth._skills);
      setSkillList(auth._skills);
    }
  }, [auth]);

  const onCheckCheckBox = (e, skills, skillId) => {
    // console.log("single check", e.target.checked, skills);

    if (checkAll && evaluateSkills.length === 1 && !e.target.checked) {
      setCheckAll(false);
     }
    if (e.target.checked) {
      setEvaluateSkills([...evaluateSkills, skills]);
    } else {
      setEvaluateSkills(
        evaluateSkills.filter((skill) => skill.ses_skills_id !== skillId)
      );
    }
  };

  const onCheckAll = (e) => {
    const checked = e.target.checked;
    setCheckAll(checked);
    if (checked) {
      
      let skillListNew = skillList.filter((skill) => skill.sc_attempt_number === 0);
      // console.log("Check All", skillListNew);
      setEvaluateSkills(skillListNew);
    } else {
      setEvaluateSkills([]);
    }
  };

  const startEvaluation = (e) => {
    localStorage.setItem("skillData", JSON.stringify(evaluateSkills));
    dispatch(setSkillData(evaluateSkills));
    navigate("/employee/self-assessment/mcq");
  };

  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader sx={{ paddingBottom: 0 }} title={"Employee Skills"} />
      <Divider />
      <CardContent>
        <FormControlLabel
          control={<Checkbox checked={checkAll} onChange={onCheckAll} />}
          label="Check All"
          sx={{ width: "100%", marginBottom: 2 }}
        />
        <Divider sx={{ marginBottom: 2 }} />
        <List
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: 1,
          }}
        >
          {skillList.map((skill, index) => (
            <ListItem key={index} sx={{ padding: 0, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      evaluateSkills.some(
                        (s) => s.ses_skills_id === skill.ses_skills_id
                      ) && skill.sc_attempt_number === 0
                    }
                    onChange={(e) =>
                      onCheckCheckBox(e, skill, skill.ses_skills_id)
                    }
                  />
                }
                label={skill.ses_skills_name}
                sx={{ width: "100%" }}
                disabled={skill.sc_attempt_number > 0}
              />
            </ListItem>
          ))}
        </List>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={startEvaluation}
            disabled={evaluateSkills.length === 0}
          >
            Start Evaluation
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.userData,
});

const mapDispatchToProps = {
  authUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(EvaluateSkills);
