import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Typography,
    Avatar,
    Stack,
    TextField,
    Grid,
    FormControlLabel,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardHeader,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Canvas, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { _getallAvatar, _updateAvatar, _uploadAvatar } from "../../services/avatar.service";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { toast } from "react-toastify";
const AiAvatar = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [uploadedAvatars, setUploadedAvatars] = useState([]);
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [isGridEnabled, setIsGridEnabled] = useState(false);
    const [avatarsData, setAvatarsData] = useState(null);
    const [voiceType, setVoiceType] = useState("male"); // State for voice type

    // Component to render a GLB model
    const ModelViewer = ({ modelUrl }) => {
        const { scene } = useGLTF(modelUrl); // Replace with your GLB model path
        return (
            <primitive
                object={scene}
                scale={[5, 4.5, 2.5]} // Adjust scale
                position={[0, -6.5, -2]} // Adjust position to align vertically
                rotation={[0.4, 0, 0]} // Slight tilt for a natural look
            />
        );
    };

      // Auto-select the radio button where avog_is_avatar is true
    useEffect(() => {
        if (avatarsData && avatarsData.length) {
            const defaultAvatar = avatarsData.find((avatar) => avatar.avog_is_avatar === true);
            if (defaultAvatar) {
                setIsGridEnabled(true);
                setSelectedAvatar(defaultAvatar.avog_id);
                setVoiceType(defaultAvatar?.avog_avatar_voice?defaultAvatar?.avog_avatar_voice:"male");
            } else {
               setSelectedAvatar(false);
            }
        }
        
    }, [avatarsData]);

    const fetchallAvatar = async () => {
        const data = await _getallAvatar();
        setAvatarsData(data);
        
    };
    const disabledEnableAvatar = async () => {
        let data = {
            avatar_id: null,
            avatar_status:isGridEnabled,
          }
        await _updateAvatar(data);
    }
    useEffect(() => {
        fetchallAvatar(); 
    }, []);
    useEffect(() => {
        if(!isGridEnabled) {
            setSelectedAvatar(null);
        } else if(isGridEnabled && selectedAvatar && avatarsData && avatarsData.length ) {
            handleSave();
        }
    }, [isGridEnabled, voiceType]);

    useEffect(() => {
        setIsGridEnabled(false);
        if(!selectedAvatar && avatarsData) {
            setIsGridEnabled(false);
            handleSave();
        } else if (selectedAvatar && avatarsData && avatarsData.length) {
            setIsGridEnabled(true);
            handleSave();
        }
    }, [selectedAvatar]);

    // Handle File Selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    // Handle Upload
    const handleUpload = async () => {
        if (selectedFile) {
            // Check if the selected file has a .glb extension
            const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
            if (fileExtension !== 'glb') {
                alert("Only .glb files are allowed. Please select a valid file.");
                return;
            }
            const formData = new FormData();
            formData.append("avatar", selectedFile);
            await _uploadAvatar(formData);
            window.location.reload();
            // const newAvatar = {
            //     id: Date.now(),
            //     name: selectedFile.name,
            //     url: preview,
            // };
            // setUploadedAvatars([...uploadedAvatars, newAvatar]);
            // setSelectedFile(null);
            // setPreview(null);
            // alert("Avatar uploaded successfully!");
        } else {
            alert("Please select a file to upload.");
        }
    };
    

    // Save Selected Avatar
    const handleSave = async () => {
        // if (selectedAvatar) {
            let data = {
                  avatar_id: selectedAvatar,
                  avatar_status:isGridEnabled,
                  avog_avatar_voice:voiceType
                }
        let response = await _updateAvatar(data);
        toast.success(response.message);
        // fetchallAvatar();
        // } else {
        //     alert("Please select an avatar to save.");
        // }
    };

    const handleAvatarSelection = (value) => {
        setSelectedAvatar(value);
        console.log(value);
    }

    const handleVoiceTypeChange = (event) => {
        setVoiceType(event.target.value);
    };

    return (
        <Box sx={{ display: "flex", height: "100vh", width: "100%" }}>
            {/* Left Panel: Upload Section */}
            <Box
                sx={{
                    width: "20%",
                    padding: 2,
                    borderRight: "1px solid #ddd",
                    boxShadow: 1,
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: 2 }}
                >
                    <SmartToyIcon /> Upload Avatar
                </Typography>

                <Avatar
                    src={preview || ""}
                    sx={{
                        width: 120,
                        height: 120,
                        margin: "auto",
                        marginBottom: 2,
                        backgroundColor: "#e0e0e0",
                    }}
                >
                    {!preview && <AddPhotoAlternateIcon fontSize="large" />}
                </Avatar>

                <Stack direction="column" spacing={2} alignItems="center">
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        fullWidth
                    >
                        Select Avatar
                        <input
                            type="file"
                            hidden
                            onChange={handleFileChange}
                        />
                    </Button>

                    {selectedFile && (
                        <TextField
                            value={selectedFile.name}
                            variant="outlined"
                            disabled
                            fullWidth
                        />
                    )}

                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleUpload}
                        fullWidth
                    >
                        Upload
                    </Button>
                    <Typography>Create your own avatar <a target="_blank" href="https://readyplayer.me/avatar">click here</a></Typography>
                </Stack>
            </Box>

            {/* Right Panel: Grid Section */}
            <Box
                sx={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 2,
                }}
            >
                {/* Toggle Section */}


                {/* Grid View */}
                <Card sx={{ height: "100%", boxShadow: 2, marginTop: "0px !important" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
                        <CardHeader title="Uploaded Avatars" />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isGridEnabled}
                                    onChange={() => {
                                        if (isGridEnabled) {
                                            setIsGridEnabled(false); // Only allow disabling if it is currently enabled
                                        }
                                    }}
                                    size="small"
                                    sx={{ marginRight: 1 }}
                                />
                            }
                            label="Enable Avatar"
                        />
                    </Box>

                    <CardContent
                        sx={{
                            overflowY: "auto",
                            maxHeight: "calc(100vh - 150px)",
                        }}
                    >
                         <Typography variant="h6" gutterBottom>
                            Select Voice Type
                        </Typography>
                        <RadioGroup
                            row
                            value={voiceType}
                            onChange={handleVoiceTypeChange}
                        >
                            <FormControlLabel
                                value="male"
                                control={<Radio />}
                                label="Male"
                            />
                            <FormControlLabel
                                value="female"
                                control={<Radio />}
                                label="Female"
                            />
                        </RadioGroup>
                        {avatarsData ? (
                            <>
                                <RadioGroup
                                    value={selectedAvatar}
                                    onChange={(e) => handleAvatarSelection(e.target.value)}
                                >
                                    <Grid container spacing={2}>
                                        {avatarsData.length > 0 ? (
                                            avatarsData.map((avatar) => (
                                                <Grid item xs={4} key={avatar.id}>
                                                    <Box
                                                        sx={{
                                                            height: 200,
                                                            border: "1px solid #ddd",
                                                            borderRadius: 2,
                                                            boxShadow: 1,
                                                            position: "relative",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <Canvas camera={{ position: [0, 0, 3] }}>
                                                            <ambientLight intensity={5} />
                                                            <directionalLight position={[5, 5, 5]} intensity={0.5} />
                                                            <ModelViewer modelUrl={avatar.avog_avatar_path} />
                                                        </Canvas>

                                                        {/* Center Radio Button and Label */}
                                                        <Box
                                                            sx={{
                                                                position: "absolute",
                                                                bottom: 0,
                                                                left: "50%",
                                                                transform: "translateX(-50%)", // Center horizontally
                                                                backgroundColor: "white",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                width: "100%", // Ensures full width for alignment
                                                                padding: "4px 0",
                                                            }}
                                                        >
                                                            <FormControlLabel
                                                                value={avatar.avog_id} // Key for selection
                                                                control={<Radio />}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography>No avatars uploaded yet.</Typography>
                                        )}
                                    </Grid>
                                </RadioGroup>
                                {/* <Box textAlign="right" marginTop={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSave}
                                    >
                                        Save Selected Avatar
                                    </Button>
                                </Box> */}
                            </>
                        ) : (
                            <Typography variant="body1" color="gray">
                                All Avtar currently disabled
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default AiAvatar;
