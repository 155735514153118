import React, { useState } from 'react';
import ObjViewer from '../../components/candidate/ObjViewer';
import { Card, CardContent, Button, Typography, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const PracticalMcq = () => {
  const questions = [
    {
      question: 'What type of pipe is most likely represented by this 3D model?',
      options: ['PVC Pipe', 'Copper Pipe', 'Cast Iron Pipe', 'PEX Pipe'],
    },
    {
      question: 'Based on the pipe’s appearance in the 3D model, what is the likely function of this pipe?',
      options: ['Drainage', 'Water Supply', 'Gas Line', 'Ventilation'],
    },
    {
      question: 'If this pipe were used in a plumbing system, which fitting would most likely be required to connect two sections?',
      options: ['Elbow', 'Tee', 'Coupling', 'Union'],
    },
    {
      question: 'What is the significance of the pipe\'s diameter in relation to its flow capacity?',
      options: [
        'Larger diameter means less flow',
        'Larger diameter means more flow',
        'Diameter does not affect flow capacity',
        'Diameter only affects pressure, not flow',
      ],
    },
    {
      question: 'Which material would you recommend for a pipe of this size and shape in a residential plumbing system?',
      options: ['Galvanized Steel', 'Copper', 'PVC', 'Lead'],
    },
    {
      question: 'If the internal diameter of the pipe in the 3D model is 50 mm, what is the cross-sectional area of the pipe (in mm²)?',
      options: ['1,963.5 mm²', '2,827.4 mm²', '1,256.6 mm²', '3,141.6 mm²'],
    },
    {
      question: 'Assuming the pipe in the 3D model is used for water supply and the flow velocity inside the pipe is 2 m/s, what would be the volumetric flow rate (in liters per second) for a pipe with an internal diameter of 0.1 meters?',
      options: ['15.7 L/s', '3.14 L/s', '6.28 L/s', '12.56 L/s'],
    },
    {
      question: 'If the length of the pipe in the 3D model is 10 meters and it has a constant internal diameter of 100 mm, what is the total internal volume of the pipe (in liters)?',
      options: ['78.5 liters', '62.8 liters', '50.2 liters', '98.1 liters'],
    },
    {
      question: 'If the pipe in the 3D model carries water at a pressure of 200 kPa and you reduce the pipe diameter by half, what happens to the pressure in the narrower section (assuming ideal conditions)?',
      options: ['Pressure increases', 'Pressure decreases', 'Pressure remains the same', 'Pressure doubles'],
    },
    {
      question: 'The pipe in the 3D model is transporting water. If the flow rate needs to be reduced by 30%, what should the plumber do?',
      options: ['Increase the pipe length', 'Decrease the pipe diameter', 'Increase the pipe diameter', 'Decrease the water pressure'],
    },
  ];

  const [responses, setResponses] = useState(Array(questions.length).fill(null));
  const [error, setError] = useState('');

  const handleOptionChange = (questionIndex, optionIndex) => {
    const updatedResponses = [...responses];
    updatedResponses[questionIndex] = optionIndex;
    setResponses(updatedResponses);
  };

  const handleSubmit = async () => {
    if (responses.every((response) => response === null)) {
      setError('Please answer at least one question before submitting.');
      return;
    }

    const payload = questions.map((question, index) => ({
      question: question.question,
      selectedOption: question.options[responses[index]] || null,
    }));

    try {
      const response = await fetch('/api/submit-mcq', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ answers: payload }),
      });
      if (response.ok) {
        alert('Responses submitted successfully.');
      } else {
        alert('Failed to submit responses.');
      }
    } catch (error) {
      alert('Error submitting responses.');
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Practical MCQ Assessment
      </Typography>

      <Card style={{ marginBottom: '20px' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            3D Model Viewer
          </Typography>
          <ObjViewer />
        </CardContent>
      </Card>

      <Typography variant="h5" gutterBottom>
        Multiple Choice Questions
      </Typography>

      {questions.map((question, index) => (
        <Card key={index} style={{ marginBottom: '20px' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {index + 1}. {question.question}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                value={responses[index]}
                onChange={(e) => handleOptionChange(index, parseInt(e.target.value))}
              >
                {question.options.map((option, optionIndex) => (
                  <FormControlLabel
                    key={optionIndex}
                    value={optionIndex}
                    control={<Radio />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </CardContent>
        </Card>
      ))}

      {error && (
        <Typography variant="body2" color="error" style={{ marginBottom: '20px' }}>
          {error}
        </Typography>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ width: '100%', padding: '10px 20px' }}
      >
        Submit
      </Button>
    </div>
  );
};

export default PracticalMcq;
