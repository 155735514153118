// ViolationList.jsx
import React from 'react';
import { Box, Typography } from '@mui/material';

export const ViolationList = ({ violations, icon: Icon, color }) => (
  <Box>
    {violations.map((violation, index) => (
      <Box key={index} display="flex" alignItems="center" gap={1} mb={0.5}>
        <Icon sx={{ color, fontSize: 20 }} />
        <Typography variant="body2">
          {violation.type}
        </Typography>
      </Box>
    ))}
    {violations.length === 0 && (
      <Typography variant="body2" color="text.secondary">
        No violations detected
      </Typography>
    )}
  </Box>
);