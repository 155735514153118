import React, { useEffect, useState } from 'react';
import { Typography } from "@mui/material";

const QuestionDisplay = ({ test, currentQuestionIndex, handleFunction }) => {
  // console.log(test[currentQuestionIndex].q_time * 60);
  const [timeLeft, setTimeLeft] = useState(test[currentQuestionIndex].q_time * 60); // Convert minutes to seconds
  
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000); // Decrease time every second

      return () => clearInterval(timer); // Cleanup on unmount or index change
    } else {
      if (test[currentQuestionIndex].q_time != 0) {
        handleFunction(); // Call handleFunction when time reaches 0
      }
    }
  }, [timeLeft, handleFunction]);

  // Format timeLeft to mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <Typography variant="h6" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>
        {currentQuestionIndex + 1}. {test[currentQuestionIndex].q_name}
      </span>
      {timeLeft > 0 && <span>{formatTime(timeLeft)}</span>}
    </Typography>
  );
};

export default QuestionDisplay;
