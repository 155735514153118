import React, { useEffect, useState } from "react";
// import {
//   getApplicant,
//   getApplicantResume,
//   getApplicantReport,
//   viewApplicantReport,
//   deleteApplicant
// } from "../../redux/actions/applicant";
import { updateCandidate , sendInviteFromCandidateprofile, insertCandidate } from "../../redux/actions/candidate";
import { connect } from "react-redux";
import { useParams, Link, useSearchParams, useNavigate } from "react-router-dom";
import { camalize, fixToTwoDecimal } from "../../utils";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import SmartDisplayTwoToneIcon from "@mui/icons-material/SmartDisplayTwoTone";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { releaseOffer, scheduleSecRound } from "../../redux/actions/candidate";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import { getSkillLevel } from "../../utils/index"
import AssessmentIcon from "@mui/icons-material/Assessment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AlertDialog from "../../components/Dialog";
import SendInviteDialog from '../common/SendInviteDialog'; // Import the new component

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 550px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const AddCandidate = ({
  releaseOffer,
  scheduleSecRound,
  insertCandidate,
  updateCandidate,
  deleteApplicant,
  sendInviteFromCandidateprofile
}) => {
  const [searchParams] = useSearchParams();
//   const edit = searchParams.get("edit");
  const navigate = useNavigate();
  const [applicantData, setApplicantData] = useState(null);
  const [showPopupSecRound, setShowPopupSecRound] = useState(false);
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);
  const [isEdit, setIsEdit] = useState(true);
  const [candEmail, setCandEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [exp, setExp] = useState("");
  const [np, setNp] = useState("");
  const [remark, setRemark] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [skills, setSkills] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState("");
  const [contentDelete, setContentDelete] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
//   const { candidateId } = useParams();
  const { batchId, fileName } = useParams();
  let BASE_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;
let orgName = localStorage.getItem('org_name');
let API_URL = '';
if (orgName) {
    API_URL = `${BASE_URL}/${orgName}/api/v1`
} else {
    API_URL = `${BASE_URL}/api/v1`
}

  //all invite model related states
  const [showSendInviteModal, setShowSendInviteModal] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [mcqChecked, setMcqChecked] = useState(false);
  const [codeChecked, setCodeChecked] = useState(false);
  const [aiAssistanceCheck, setAiAssistanceCheck] = useState(true);
  const [emailContent, setEmailContent] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [mcqCount, setMcqCount] = useState(20);
  const [codeCount, setCodeCount] = useState(2);
  const [mcqError, setMcqError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [mcqTime, setMcqTime] = useState(15);
  const [codeTime, setCodeTime] = useState(15);
  const [aiInterviewTime, setAiInterviewTime] = useState(15);
  const [reschedule, setReshedule] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");

  const [linkExpTime, setLinkExpTime] = useState(24);



  const handleLinkExpTimeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0) {
      setLinkExpTime(value);
    }
  };
  // all invite model related functions
  const handleCloseSendInviteModal = () => {
    setShowSendInviteModal(false);
    setSelectedCandidateId(null);
  };

  const openConfirmationDialog = () => {
    setShowConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };


  const confirmAndSendInvite = () => {
    // sendInviteCandidate(params.row.apct_id);
    let bodyData = {
      mcq: mcqChecked,
      coding: codeChecked,
      interview: aiAssistanceCheck,
      email_body: emailContent,
      email_subject: emailSubject,
      mcqCount: mcqCount,
      codeCount: codeCount,
      mcqTime: mcqTime,
      codeTime: codeTime,
      aiInterviewTime: aiInterviewTime,
      reschedule: reschedule,
      linkExpTime: linkExpTime
    };
    // sendInvite(
    //   selectedCandidateId,
    //   jDID || jb_id,
    //   bt_id || bT_ID,
    //   paginationModals.page,
    //   paginationModals.pageSize,
    //   bodyData
    // );
    sendInviteFromCandidateprofile(
      selectedCandidateId,
      bodyData
    );
    setShowConfirmationDialog(false);
    setShowSendInviteModal(false);
    setSelectedCandidateId(null);
  };

  const handleMcqCountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setMcqCount(value);
    if (value % 5 === 0 && value >= 10 && value <= 100) {      
      setMcqError(false);
    } else {
      setMcqError(true);
    }
  };

  const handleCodeCountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setCodeCount(value);
    if (value >= 2 && value <= 10) {
      setCodeError(false);
    } else {
      setCodeError(true);
    }
  };

  // Validation handlers for time settings
  const handleMcqTimeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0) {
      setMcqTime(value);
    }
  };

  const handleCodeTimeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0) {
      setCodeTime(value);
    }
  };

  const handleAiInterviewTimeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0) {
      setAiInterviewTime(value);
    }
  };

  const openSendInviteModal = (candidateId) => {
    setMcqChecked(false);
    setCodeChecked(false);
    // setEmailContent(`<p>Hi {{userName}},</p>
    //             <p>You have been invited for an AI Interview for the position of {{jobName}}.</p>
    //             <p>Please find below details:</p>
    //             <p>Username: {{userEmail}}</p>
    //             <p>Password: {{userPassword}}</p>               
    //             <p>Please click on below link to start interview</p>
    //             <a href="{{link}}" target="_blank">Click Here</a>
    //             <div>Thank you</div>
    //             <div>Team</div>
    //             <div>Girikon</div>`);

    setEmailContent(` <p>Dear {{userName}},</p>
      <p>We are delighted to invite you to participate in the next round of our recruitment process for the position of <strong>{{jobName}}</strong>. As part of our selection process, you will be completing an interview assessment on an AI-powered platform.</p>
      <p><strong>Your Interview Details:</strong></p>
      <ul>
          <li><strong>Access the interview platform using the link below:</strong> <a href="{{link}}" target="_blank">Click Here</a></li>
          <li><strong>Username:</strong> {{userEmail}}</li>
          <li><strong>Password:</strong> {{userPassword}}</li>
      </ul>
      <p><strong>How to Proceed:</strong></p>
      <ol>
          <li>Use the link above to enter the interview platform.</li>
          <li>Log in with the username and password specified above.</li>
          <li>After logging in, grant consent to allow camera and microphone access.</li>
          <li>Questions will be shared on the screen, and you can answer them once the narration of the question stops.</li>
      </ol>
      <p>For the best experience, please take the interview in a quiet area with a reliable internet connection.</p>
      <p>The Interview link will expire 30 minutes after the mail is received.</p>
      <p>Regards</p>
      <p><strong>Human Resources Department</strong></p>
      <p><strong>ORIX India</strong></p>`);
    setSelectedCandidateId(candidateId);
    setShowSendInviteModal(true);
  };

  const openResheduleSendInviteModal = (candidateId) => {
    setReshedule(true);
    setMcqChecked(false);
    setCodeChecked(false);
    // setEmailContent(`<p>Hi {{userName}},</p>
    //             <p>You have been invited for an AI Interview for the position of {{jobName}}.</p>
    //             <p>Please find below details:</p>
    //             <p>Username: {{userEmail}}</p>
    //             <p>Password: {{userPassword}}</p>               
    //             <p>Please click on below link to start interview</p>
    //             <a href="{{link}}" target="_blank">Click Here</a>
    //             <div>Thank you</div>
    //             <div>Team</div>
    //             <div>Girikon</div>`);
    setSelectedCandidateId(candidateId);
    setShowSendInviteModal(true);
  };

  // End of send invite related functions and states




//   useEffect(() => {
//     if (applicant && applicant.applicant) {
//       setApplicantData(applicant.applicant);
//       setCandEmail(applicant.applicant.apct_email);
//       setMobile(applicant.applicant.apct_mobile);
//       setNp(applicant.applicant.apct_np);
//       setRemark(applicant.applicant.apct_hr_remarks);
//       setName(applicant.applicant.apct_name);
//       setDesignation(applicant.applicant.apct_designation);
//       setExp(applicant.applicant.apct_exp);
//     }
//     if (applicant && applicant.skills && applicant.skills.length > 0) {
//       setSkills(applicant.skills);
//     }

//     console.log("applicant", applicant);
//   }, [applicant]);

//   useEffect(() => {
//     if (applicant_resume) {
//       setFile(applicant_resume);
//     }
//   }, [applicant_resume]);

//   useEffect(() => {
//     if (edit) {
//       setIsEdit(true);
//     }
//   }, [edit]);

  // const handleDownloadReport = () => {
  //   getApplicantReport(candidateId);
  // };

  // const handleViewReport = () => {
  //   viewApplicantReport(candidateId);
  // };

   const deletApct = async (name) => {
    
     setTitleDelete("Delete Applicant");
     setContentDelete(`Are you sure you want to delete applicant :  ${name}?`);
     setOpenDelete(true);
   };

   useEffect(() => {
     if (confirmDelete) {
       deleteApplicant(
         applicantData && applicantData.apct_id,
         null,
         0,
         50
       );
       setConfirmDelete(false);
       navigate("/hr/candidates");
     }
   }, [confirmDelete]);
  

  const onClickSecRound = async () => {
    // if (candidateId) {
    //   await scheduleSecRound({
    //     apct_id: candidateId,
    //     email: email,
    //   });
    //   setEmail(null);
    //   setShowPopupSecRound(false);
    // }
  };

  const onCancelSecRound = () => {
    setEmail(null);
    setShowPopupSecRound(false);
  };

//   const offerReleased = () => {
//     if (candidateId) {
//       releaseOffer(candidateId);
//     }
//   };

 const insertInfo = async () => {
    let obj = {
      name,
      designation,
      mobile,
      np,
      exp,
      remark,
      email: candEmail,
      fileName: fileName,
    };

    console.log("insert obj", obj);

    await insertCandidate(obj, batchId);
    navigate(`/hr/batches/batch-detail/${batchId}`);
  };


  const sendInviteCandidate = (apct_id) => {
    sendInviteFromCandidateprofile(
      apct_id
    );
  };

  return (
    <>
      <AlertDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={titleDelete}
        setConfirm={setConfirmDelete}
        content={contentDelete}
      />
      <Dialog
        open={showPopupSecRound}
        onClose={() => onCancelSecRound(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Schedule Second Round</DialogTitle>
        <DialogContent>
          <Typography>Enter Interviewer's Email:</Typography>
          <TextField
            type="email"
            autoFocus
            fullWidth
            minWidth="sm"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onCancelSecRound(false)}>Cancel</Button>
          <Button
            // onClick={() => onClickSecRound()}
            color="primary"
            disabled={!email}
          >
            Schedule
          </Button>
        </DialogActions>
      </Dialog>
      <SendInviteDialog
        open={showSendInviteModal}
        handleClose={handleCloseSendInviteModal}
        confirmAndSendInvite={confirmAndSendInvite}
        openConfirmationDialog={openConfirmationDialog}
        closeConfirmationDialog={closeConfirmationDialog}
        mcqChecked={mcqChecked}
        setMcqChecked={setMcqChecked}
        codeChecked={codeChecked}
        setCodeChecked={setCodeChecked}
        aiAssistanceCheck={aiAssistanceCheck}
        setAiAssistanceCheck={setAiAssistanceCheck}
        emailContent={emailContent}
        setEmailContent={setEmailContent}
        setEmailSubject={setEmailSubject}
        mcqCount={mcqCount}
        handleMcqCountChange={handleMcqCountChange}
        mcqError={mcqError}
        codeCount={codeCount}
        handleCodeCountChange={handleCodeCountChange}
        codeError={codeError}
        mcqTime={mcqTime}
        handleMcqTimeChange={handleMcqTimeChange}
        codeTime={codeTime}
        handleCodeTimeChange={handleCodeTimeChange}
        aiInterviewTime={aiInterviewTime}
        linkExpTime={linkExpTime}
        handleAiInterviewTimeChange={handleAiInterviewTimeChange}          
        handleLinkExpTimeChange={handleLinkExpTimeChange}
        showConfirmationDialog={showConfirmationDialog}
      />
      <Box sx={{ mt: 1 }}>
        <Card xs={12} sx={{ mt: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" sx={{ ml: 2, mt: 2 }}>
              Candidate Details
            </Typography>
              <div>                  
                <Button
                  variant="text"
                  size={"small"}
                  onClick={() => insertInfo()}
                  sx={{ mr: 2, mt: 1, color: "green" }}
                >
                  <SaveIcon /> Save
                </Button>
              </div>            
          </Box>

          <Divider />
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Name :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              {isEdit ? (
                <TextField
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={!isEdit}
                />
              ) : (
                <Typography variant="subtitle1" fontSize={14}>
                  {" "}
                  {name}
                </Typography>
              )}
            </Grid>

            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                AI Interview Status :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography variant="subtitle1" fontSize={14}>
                {applicantData ? camalize(applicantData.apct_stage) : ""}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Designation :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              {isEdit ? (
                <TextField
                  name="designation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  disabled={!isEdit}
                />
              ) : (
                <Typography variant="subtitle1" fontSize={14}>
                  {designation}
                </Typography>
              )}
            </Grid>

            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                AI Interview Rating :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              {applicantData && applicantData.apct_intw_rating ? (
                <Link to={`/hr/ai_score/v2/${applicantData.apct_id}`}>
                  {" "}
                  {applicantData.apct_intw_rating}/10{" "}
                </Link>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Email :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              {isEdit ? (
                <TextField
                  name="email"
                  value={candEmail}
                  onChange={(e) => setCandEmail(e.target.value)}
                  disabled={!isEdit}
                />
              ) : (
                <Typography variant="subtitle1" fontSize={14}>
                  {candEmail}
                </Typography>
              )}
            </Grid>

            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                CV Match Score :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography variant="subtitle1" fontSize={14}>
                {applicantData && applicantData.apct_match_rate ? (
                  <>{applicantData.apct_match_rate}%</>
                ) : (
                  ""
                )}
              </Typography>
            </Grid>

            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Mobile :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              {isEdit ? (
                <TextField
                  name="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  disabled={!isEdit}
                />
              ) : (
                <Typography variant="subtitle1" fontSize={14}>
                  {mobile}
                </Typography>
              )}
            </Grid>

            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Second Round :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography variant="subtitle1" fontSize={14}>
                {applicantData
                  ? applicantData.apct_sec_round_scheduled_with
                  : ""}
              </Typography>
            </Grid>

            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Experience :
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4}>
              {isEdit ? (
                <TextField
                  name="exp"
                  value={exp}
                  onChange={(e) => setExp(e.target.value)}
                  disabled={!isEdit}
                />
              ) : (
                <Typography variant="subtitle1" fontSize={14}>
                  {exp}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Offer Released :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography variant="subtitle1" fontSize={14}>
                {applicantData && applicantData.apct_offer_released
                  ? "Yes"
                  : "No"}
              </Typography>
            </Grid>

            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Notice Period :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              {isEdit ? (
                <TextField
                  name="np"
                  value={np}
                  onChange={(e) => setNp(e.target.value)}
                  disabled={!isEdit}
                />
              ) : (
                <Typography variant="subtitle1" fontSize={14}>
                  {np}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} sm={6}></Grid>

            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Remark :
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              {isEdit ? (
                <Textarea
                  minRows={4}
                  minWidth="sm"
                  value={remark}
                  sx={{ width: "72%" }}
                  onChange={(e) => setRemark(e.target.value)}
                />
              ) : (
                <Typography variant="subtitle1" fontSize={14}>
                  {remark}
                </Typography>
              )}
            </Grid>
          </Grid>         
        </Card>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  releaseOffer,
  insertCandidate,
  scheduleSecRound,
  updateCandidate,
  sendInviteFromCandidateprofile,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCandidate);
