import { Route, Routes, Navigate } from "react-router-dom";
import AdminDashboard from "../pages/admin/Dashboard";
import OrgAdmins from "../pages/admin/AllOrgAdmins";
import CreateOrganizationAdmin from "../pages/admin/CreateOrganizationAdmin";
import CreditRechargeList from "../pages/admin/CreditRechargeList";
import CreditRechargeForm from "../pages/admin/CreditRechargeForm";
import CreditChargesList from "../pages/admin/CreditChargesList";
import CreditChargeForm from "../pages/admin/CreditChargeForm";

const AdminRoutes = () => {
  // Define routes in an array for better organization
  const routes = [
    { path: "/admin/dashboard", element: <AdminDashboard /> },
    { path: "/admin/org-admins", element: <OrgAdmins /> },
    { path: "/admin/create-organization-admin", element: <CreateOrganizationAdmin /> },
    { path: "/admin/credit-recharge", element: <CreditRechargeList /> },
    { path: "/admin/credit-recharge/new", element: <CreditRechargeForm /> },
    { path: "/admin/credit-recharge/edit/:id", element: <CreditRechargeForm /> },
    { path: "/admin/credit-charges", element: <CreditChargesList /> },
    { path: "/admin/credit-charge/new", element: <CreditChargeForm /> },
    { path: "/admin/credit-charge/edit/:id", element: <CreditChargeForm /> },
  ];

  return (
    <Routes>
      {routes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
      <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
    </Routes>
  );
};

export default AdminRoutes;
