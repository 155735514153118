import React from 'react';
import { Card, CardContent, Typography, Box, IconButton, Grid, Collapse } from '@mui/material';
import { ViolationList } from './ViolationList';
import { Person, Videocam, Message } from '@mui/icons-material';

export const QuestionCard = ({ question }) => {
  const totalViolationsData = question.totalViolations;

  return (
    <Card>
      <Box 
        sx={{ 
          p: 2
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">
            Question {question.number}
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body2">
              {totalViolationsData} violation{totalViolationsData !== 1 ? 's' : ''}
            </Typography>
          </Box>
        </Box>
      </Box>
      <CardContent sx={{ pt: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="primary" gutterBottom>
              Face Violations
            </Typography>
            <ViolationList 
              violations={question.faceViolations}
              icon={Person}
              color="#1976d2"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="secondary" gutterBottom>
              Lip Sync Violations
            </Typography>
            <ViolationList 
              violations={question.lipSyncViolations}
              icon={Videocam}
              color="#9c27b0"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" sx={{ color: 'warning.main' }} gutterBottom>
              Voice Violations
            </Typography>
            <ViolationList 
              violations={question.voiceViolations}
              icon={Message}
              color="#ed6c02"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
