import React from "react";
import {
  Card,
  CardContent,
  Button,
  TextField,
  Box,
  Modal,
} from "@mui/material";
import { styled } from "@mui/system";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import {
  Userprofile,
  changePassword,
  updateUserProfileDetails,
  uploadPicture,
} from "../../redux/actions/auth";
import { connect } from "react-redux";
import { modalPopUpStyle } from "../../styles/getLPTheme";
import ImageCropper from "./ImageCropper";
import "../../utils/timezone.min.js";
import Select from "react-select";
import moment from "moment";
import { FaInfoCircle } from "react-icons/fa";
import { toast } from "react-toastify";

const UserProfileCard = styled(Card)({
  marginBottom: "10px",
  padding: "20px",
});

const UsersProfile = ({
  Userprofile,
  currentUserDetails,
  changePassword,
  uploadPicture,
  updateUserProfileDetails,
}) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [totalexp, settotalexp] = useState("");
  const [relevantexp, setrelevantexp] = useState("");
  const [open, setOpen] = React.useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    resetPassword: "",
  });
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 25, aspect: 1 });
  const [image, setImage] = useState(null);
  const [canvas, setCanvas] = useState(document.createElement("canvas"));
  const [openCropModal, setOpenCropModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [timeZone, setTimeZone] = useState("");
  const [timeZoneLabel, setTimeZoneLabel] = useState("");
  const [daylightSaving, setDaylightSaving] = useState(false);

  const [selectedDateFormat, setSelectedDateFormat] = useState(null);
  const [selectedDateTimeFormat, setSelectedDateTimeFormat] = useState(null);

  const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
  useEffect(() => {
    Userprofile();
  }, []);
  useEffect(() => {
    if (currentUserDetails) {
      setUsername(currentUserDetails?.u_name);
      setEmail(currentUserDetails?.u_email);
      setMobileNumber(currentUserDetails?.u_mobile);
      settotalexp(
        currentUserDetails &&
          currentUserDetails.exp ? currentUserDetails.exp : currentUserDetails.u_total_experience
      );
      setrelevantexp(
        currentUserDetails &&
          currentUserDetails.rel_exp ? currentUserDetails.rel_exp : currentUserDetails.u_relevant_experience
      );
      setTimeZone(currentUserDetails?.u_timezone);
      setDaylightSaving(currentUserDetails?.u_day_saving);
      setSelectedDateTimeFormat(currentUserDetails?.u_date_time_format);
      handleTimezoneLabelChange(currentUserDetails?.u_timezone);
    }
  }, [currentUserDetails]);
  const onChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onChangeMobileNumber = (event) => {
    setMobileNumber(event.target.value);
  };

  const onChangetotalexp = (event) => {
    if (parseFloat(event.target.value) < 0 || isNaN(parseFloat(event.target.value))) {
      settotalexp(null);
      event.target.value = null;
      return;
    }
    settotalexp(event.target.value);
  };
  const onChangerelevantexp = (event) => {
    if (parseFloat(event.target.value) < 0 || isNaN(parseFloat(event.target.value))) {
      setrelevantexp(null);
      event.target.value = null;
      return;
    }
    setrelevantexp(event.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCancel = () => {
    toggleModalClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let obj = {
      email: email,
      oldpassword: formData.password,
      password: formData.resetPassword,
    };

    changePassword(obj)
      .then(() => {
        setFormData({
          email: "",
          password: "",
          resetPassword: "",
        });
        toggleModalClose();
        toast.success("Password Updated!");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error);
      });
  };

  const toggleModalOpen = () => {
    setOpen(true);
  };

  const toggleModalClose = () => {
    setFormData({
      ...formData,
      password: '',
      resetPassword: '',
    });
    setOpen(false);
    setOpenCropModal(false);
  };

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      handleSelectImage(event.target.files[0]);
      setOpenCropModal(true);
    }
  };

  const handleUpload = async (photo) => {
    try {
      const formData = new FormData();
      formData.append("image", photo);
      uploadPicture(formData)
        .then(() => {
          Userprofile();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  };

  const handleSelectImage = (file) => {
    setSrc(URL.createObjectURL(file));
    setCrop({ unit: "%", width: 25, aspect: 1 });
  };

  const handleImageLoad = (img) => {
    img.style.maxHeight = "500px";
    setImage(img);
  };

  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const handleCropImage = () => {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    handleCroppedImage();
  };

  const handleCroppedImage = () => {
    canvas.toBlob((blob) => {
      handleUpload(blob);
      setOpenCropModal(false);
    }, "image/jpeg");
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleTimezoneLabelChange = (timezone) => {
    const selectedOption = timeZoneOptions.find(
      (option) => option.key === timezone
    );
    selectedOption
      ? setTimeZoneLabel(selectedOption.label)
      : setTimeZoneLabel("");
  };

  const handleTimeZoneChange = (event) => {
    // console.log(event.key);
    setTimeZone(event.key);
    handleTimezoneLabelChange(event.key);
  };

  const getFlagImageUrl = (countryCode) => {
    return `/timezone/flags/64/${countryCode.toLowerCase()}.png`;
  };

  const timeZoneOptions = window.timeZoneList().map((timeZone) => ({
    value:
      timeZone.value +
      " " +
      timeZone.type +
      " " +
      timeZone.key +
      " " +
      timeZone.country,
    key: timeZone.type + "/" + timeZone.key,
    label: (
      <div>
        <img
          src={getFlagImageUrl(timeZone.url)}
          alt={timeZone.country}
          style={{ width: 20, marginRight: 5 }}
        />
        {`(GMT${timeZone.value}) ${timeZone.type}/${timeZone.key}, ${timeZone.country}`}
      </div>
    ),
  }));

  const handleDaylightSavingChange = (event) => {
    // console.log(event.target.checked);
    setDaylightSaving(event.target.checked);
  };

  const handleUpdateDetails = async () => {
    if (parseFloat(relevantexp) > parseFloat(totalexp)) {
      toast.error("Relevant years of experience can't be more than total years of experience.");
      return;
    }
    if (
      currentUserDetails?.u_email !== email ||
      currentUserDetails?.u_timezone !== timeZone ||
      currentUserDetails?.u_day_saving !== daylightSaving ||
      currentUserDetails?.u_total_experience !== totalexp ||
      currentUserDetails?.u_relevant_experience !== relevantexp ||
      currentUserDetails?.u_date_time_format !== selectedDateTimeFormat
    ) {
      let obj = {
        email: email,
        timezone: timeZone,
        daylightSaving: daylightSaving,
        experience: totalexp,
        relevantExperience: relevantexp,
        dateTimeFormat: selectedDateTimeFormat,
      };
      const user = JSON.parse(localStorage.getItem("user"));
      user["u_timezone"] = timeZone;
      user["u_date_time_format"] = selectedDateTimeFormat;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("org_name", user.u_org_name);

      try {
        updateUserProfileDetails(obj);
        Userprofile();
      } catch (error) {
        // console.log(error);
      }
    } else {
      toast.info("Update your details first!");
    }
  };

  const handleDateFormatChange = (selectedOption) => {
    setSelectedDateFormat(selectedOption);
    formatTodayDate();
  };

  const handleDateTimeFormatChange = (selectedOption) => {
    setSelectedDateTimeFormat(selectedOption.label);
  };

  let momentValue = moment();
  const [dateList, setDateList] = useState({
    dateFormatExample: "",
    dateTimeFormatExample: "",
    dateFormatData: [
      { id: 1, label: "DD/MM/YYYY", value: momentValue.format("DD/MM/YYYY") },
      { id: 2, label: "MM/DD/YYYY", value: momentValue.format("MM/DD/YYYY") },
      { id: 3, label: "YYYY/MM/DD", value: momentValue.format("YYYY/MM/DD") },
      { id: 4, label: "DD-MM-YYYY", value: momentValue.format("DD-MM-YYYY") },
      { id: 5, label: "MM-DD-YYYY", value: momentValue.format("MM-DD-YYYY") },
      { id: 6, label: "YYYY-MM-DD", value: momentValue.format("YYYY-MM-DD") },
      { id: 7, label: "DD.MM.YYYY", value: momentValue.format("DD.MM.YYYY") },
      { id: 8, label: "MM.DD.YYYY", value: momentValue.format("MM.DD.YYYY") },
      { id: 9, label: "YYYY.MM.DD", value: momentValue.format("YYYY.MM.DD") },
      {
        id: 10,
        label: "DD MMM YYYY",
        value: momentValue.format("DD MMM YYYY"),
      },
      {
        id: 11,
        label: "MMM DD YYYY",
        value: momentValue.format("MMM DD YYYY"),
      },
      {
        id: 12,
        label: "YYYY MMM DD",
        value: momentValue.format("YYYY MMM DD"),
      },
      {
        id: 13,
        label: "DD MMMM, YYYY",
        value: momentValue.format("DD MMMM, YYYY"),
      },
      {
        id: 14,
        label: "MMMM DD, YYYY",
        value: momentValue.format("MMMM DD, YYYY"),
      },
      {
        id: 15,
        label: "YYYY, MMMM DD",
        value: momentValue.format("YYYY, MMMM DD"),
      },
      { id: 16, label: "DDMMYYYY", value: momentValue.format("DDMMYYYY") },
      { id: 17, label: "MMDDYYYY", value: momentValue.format("MMDDYYYY") },
      { id: 18, label: "YYYYMMDD", value: momentValue.format("YYYYMMDD") },
    ],
    dateTimeFormatData: [
      {
        id: 1,
        label: "DD/MM/YYYY hh:mm A",
        value: momentValue.format("DD/MM/YYYY hh:mm A"),
      },
      {
        id: 2,
        label: "DD/MM/YYYY HH:mm",
        value: momentValue.format("DD/MM/YYYY HH:mm"),
      },
      {
        id: 3,
        label: "MM/DD/YYYY hh:mm A",
        value: momentValue.format("MM/DD/YYYY hh:mm A"),
      },
      {
        id: 4,
        label: "MM/DD/YYYY HH:mm",
        value: momentValue.format("MM/DD/YYYY HH:mm"),
      },
      {
        id: 5,
        label: "YYYY/MM/DD hh:mm A",
        value: momentValue.format("YYYY/MM/DD hh:mm A"),
      },
      {
        id: 6,
        label: "YYYY/MM/DD HH:mm",
        value: momentValue.format("YYYY/MM/DD HH:mm"),
      },
      {
        id: 7,
        label: "DD-MM-YYYY hh:mm A",
        value: momentValue.format("DD-MM-YYYY hh:mm A"),
      },
      {
        id: 8,
        label: "DD-MM-YYYY HH:mm",
        value: momentValue.format("DD-MM-YYYY HH:mm"),
      },
      {
        id: 9,
        label: "MM-DD-YYYY hh:mm A",
        value: momentValue.format("MM-DD-YYYY hh:mm A"),
      },
      {
        id: 10,
        label: "MM-DD-YYYY HH:mm",
        value: momentValue.format("MM-DD-YYYY HH:mm"),
      },
      {
        id: 11,
        label: "YYYY-MM-DD hh:mm A",
        value: momentValue.format("YYYY-MM-DD hh:mm A"),
      },
      {
        id: 12,
        label: "YYYY-MM-DD HH:mm",
        value: momentValue.format("YYYY-MM-DD HH:mm"),
      },
      {
        id: 13,
        label: "DD.MM.YYYY hh:mm A",
        value: momentValue.format("DD.MM.YYYY hh:mm A"),
      },
      {
        id: 14,
        label: "DD.MM.YYYY HH:mm",
        value: momentValue.format("DD.MM.YYYY HH:mm"),
      },
      {
        id: 15,
        label: "MM.DD.YYYY hh:mm A",
        value: momentValue.format("MM.DD.YYYY hh:mm A"),
      },
      {
        id: 16,
        label: "MM.DD.YYYY HH:mm",
        value: momentValue.format("MM.DD.YYYY HH:mm"),
      },
      {
        id: 17,
        label: "YYYY.MM.DD hh:mm A",
        value: momentValue.format("YYYY.MM.DD hh:mm A"),
      },
      {
        id: 18,
        label: "YYYY.MM.DD HH:mm",
        value: momentValue.format("YYYY.MM.DD HH:mm"),
      },
      {
        id: 19,
        label: "DD MMM YYYY hh:mm A",
        value: momentValue.format("DD MMM YYYY hh:mm A"),
      },
      {
        id: 20,
        label: "DD MMM YYYY HH:mm",
        value: momentValue.format("DD MMM YYYY HH:mm"),
      },
      {
        id: 21,
        label: "MMM DD YYYY hh:mm A",
        value: momentValue.format("MMM DD YYYY hh:mm A"),
      },
      {
        id: 22,
        label: "MMM DD YYYY HH:mm",
        value: momentValue.format("MMM DD YYYY HH:mm"),
      },
      {
        id: 23,
        label: "YYYY MMM DD hh:mm A",
        value: momentValue.format("YYYY MMM DD hh:mm A"),
      },
      {
        id: 24,
        label: "YYYY MMM DD HH:mm",
        value: momentValue.format("YYYY MMM DD HH:mm"),
      },
      {
        id: 25,
        label: "DD MMMM, YYYY hh:mm A",
        value: momentValue.format("DD MMMM, YYYY hh:mm A"),
      },
      {
        id: 26,
        label: "DD MMMM, YYYY HH:mm",
        value: momentValue.format("DD MMMM, YYYY HH:mm"),
      },
      {
        id: 27,
        label: "MMMM DD, YYYY hh:mm A",
        value: momentValue.format("MMMM DD, YYYY hh:mm A"),
      },
      {
        id: 28,
        label: "MMMM DD, YYYY HH:mm",
        value: momentValue.format("MMMM DD, YYYY HH:mm"),
      },
      {
        id: 29,
        label: "YYYY, MMMM DD hh:mm A",
        value: momentValue.format("YYYY, MMMM DD hh:mm A"),
      },
      {
        id: 30,
        label: "YYYY, MMMM DD HH:mm",
        value: momentValue.format("YYYY, MMMM DD HH:mm"),
      },
      {
        id: 31,
        label: "DDMMYYYY hh:mm A",
        value: momentValue.format("DDMMYYYY hh:mm A"),
      },
      {
        id: 32,
        label: "DDMMYYYY HH:mm",
        value: momentValue.format("DDMMYYYY HH:mm"),
      },
      {
        id: 33,
        label: "MMDDYYYY hh:mm A",
        value: momentValue.format("MMDDYYYY hh:mm A"),
      },
      {
        id: 34,
        label: "MMDDYYYY HH:mm",
        value: momentValue.format("MMDDYYYY HH:mm"),
      },
      {
        id: 35,
        label: "YYYYMMDD hh:mm A",
        value: momentValue.format("YYYYMMDD hh:mm A"),
      },
      {
        id: 36,
        label: "YYYYMMDD HH:mm",
        value: momentValue.format("YYYYMMDD HH:mm"),
      },
    ],
  });

  const [todayDate, setTodayDate] = useState("");
  const [todayDateTime, setTodayDateTime] = useState("");

  const formatTodayDate = () => {
    if (selectedDateFormat) {
      const today = moment().format(selectedDateFormat.label);
      setTodayDate(today);
    }
  };

  const formatTodayDateTime = () => {
    if (selectedDateTimeFormat) {
      const today = moment().format(selectedDateTimeFormat);
      setTodayDateTime(today);
    }
  };

  useEffect(() => {
    formatTodayDate();
  }, [selectedDateFormat]);

  useEffect(() => {
    formatTodayDateTime();
  }, [selectedDateTimeFormat]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      borderRadius: 10,
    }),
  };

  return (
    <div className="marginTop">
      <h3 className="label-bold">User Details</h3>
      <div
        className="marginTop"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Modal
          open={openCropModal}
          onClose={toggleModalClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              ...modalPopUpStyle,
              "& > :not(style)": { m: 1, width: "25ch" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            noValidate
            autoComplete="off"
          >
            <ImageCropper
              crop={crop}
              src={src}
              onImageLoaded={handleImageLoad}
              onCropChange={handleCropChange}
            />

            <div style={{ width: "auto" }}>
              <Button onClick={handleCropImage}>Update</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </div>
          </Box>
        </Modal>

        {/* Account details card */}
        <UserProfileCard
          style={{ width: "60%", position: "relative", overflow: "visible" }}
        >
          <div
            style={{
              position: "absolute",
              top: "-75px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "150px",
              height: "150px",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {currentUserDetails?.u_avatar ? (
              <img
                src={`${localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL}/user/image?token=${token}&timestamp=${Date.now()}`}
                style={{
                  border: "2px solid #9b9b9b",
                  boxShadow: "0px 0px 10px 0px #9b9b9b",
                  borderRadius: "50%",
                  height: "100%",
                  width: "100%",
                  color: "#9b9b9b",
                  filter: isHovered ? "brightness(70%)" : "none",
                }}
              />
            ) : (
              <div
                style={{
                  border: "2px solid #cccccc",
                  backgroundColor: "#d9d9d9",
                  borderRadius: "50%",
                  height: "100%",
                  width: "100%",
                  filter: isHovered ? "brightness(85%)" : "none",
                }}
              >
                <PersonIcon
                  style={{
                    height: "100%",
                    width: "100%",
                    color: "#a6a6a6",
                    filter: isHovered ? "brightness(70%)" : "none",
                  }}
                />
              </div>
            )}
            <input
              type="file"
              onChange={handleFileChange}
              name="user_profile"
              id="image"
              accept="images/*"
              style={{ display: "none" }}
            />
            <label htmlFor="image">
              {isHovered && (
                <Button
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "transparent",
                    color: "#ffffff",
                  }}
                  onChange={handleFileChange}
                  component="span"
                >
                  <EditIcon />
                </Button>
              )}
            </label>
          </div>
          <CardContent style={{ marginTop: "100px" }}>
            <div className="margin-profile">
              <label className="label-bold">Username</label>
              <TextField
                placeholder="Username"
                onChange={(event) => onChangeUsername(event)}
                inputProps={{
                  disabled: currentUserDetails?.u_role !== "admin",
                }}
                value={username}
                fullWidth
              />
            </div>
            <div className="margin-profile">
              <label className="label-bold">E-mail</label>
              <TextField
                placeholder="Email"
                onChange={(event) => onChangeEmail(event)}
                value={email}
                inputProps={{
                  disabled: currentUserDetails?.u_role !== "admin",
                }}
                fullWidth
              />
            </div>
            <div className="margin-profile">
              <label className="label-bold">Mobile</label>
              <TextField
                type="number"
                placeholder="Mobile Number"
                onChange={(event) => onChangeMobileNumber(event)}
                value={mobileNumber}
                inputProps={{
                  disabled: currentUserDetails?.u_role !== "admin",
                }}
                fullWidth
              />
            </div>
            <div className="margin-profile">
              <div style={{ display: "flex" }}>
                <div style={{ width: "70%", marginRight: "10px" }}>
                  <label className="label-bold">Time Zone</label>
                  <Select
                    value={{ label: timeZoneLabel }}
                    onChange={handleTimeZoneChange}
                    options={timeZoneOptions}
                    styles={customStyles}
                    maxMenuHeight={100}
                    isSearchable
                  />
                </div>
                <div
                  style={{
                    width: "30%",
                    minWidth: "105px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <label className="label-bold" style={{ marginBottom: "5px" }}>
                    Daylight Saving
                  </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      checked={daylightSaving}
                      onChange={handleDaylightSavingChange}
                    />
                    <span style={{ marginLeft: "5px" }}>
                      Enable Daylight Saving
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="margin-profile">
              <div style={{ display: "flex" }}>
                {/* <div style={{ width: "50%", marginRight: "10px" }}>
                  <div>
                  <label className="label-bold">Date Format</label>
                  <Select
                    value={selectedDateFormat}
                    onChange={handleDateFormatChange}
                    options={dateList.dateFormatData}
                    isSearchable
                  />
                  </div>
                  <div style={{ width: "50%" }}>
                  {/* <label className="label-bold">Today's Date</label>
                  <TextField
                    placeholder="Today's Date"
                    value={todayDate}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  /> */}
                {/*<small className="badge bg-secondary">
                                          <FaInfoCircle /> Example: {todayDate}
                  </small>
                </div> 
                  
                </div>*/}
                <div style={{ width: "69%" }}>
                  <label className="label-bold">DateTime Format</label>
                  <Select
                    value={{ label: selectedDateTimeFormat }}
                    onChange={handleDateTimeFormatChange}
                    options={dateList.dateTimeFormatData}
                    styles={customStyles}
                    maxMenuHeight={200}
                    isSearchable
                  />
                  <small className="badge bg-secondary">
                    <FaInfoCircle /> Example: {todayDateTime}
                  </small>
                </div>
              </div>
            </div>

            <div className="margin-profile">
              <label className="label-bold">Total Years of Experience</label>
              <TextField
                type="number"
                placeholder="Total Years of Experience"
                onChange={(event) => onChangetotalexp(event)}
                value={totalexp}
                inputProps={{ min: 0 }}
                fullWidth
              />
            </div>
            <div className="margin-profile">
              <label className="label-bold">Relevant Years of Experience</label>
              <TextField
                type="number"
                placeholder="Relevant Years of Experience"
                onChange={(event) => onChangerelevantexp(event)}
                value={relevantexp}
                inputProps={{ min: 0 }}
                fullWidth
              />
            </div>

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button color="primary" onClick={toggleModalOpen}>
                Change Password
              </Button>
              <Button color="primary" onClick={handleUpdateDetails}>
                Update Profile
              </Button>
            </div>
          </CardContent>
        </UserProfileCard>

        <Modal
          open={open}
          onClose={toggleModalClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          className="modalContainer"
        >
          <Box
            className="modalContent"
            component="form"
            sx={modalPopUpStyle}
            noValidate
            autoComplete="off"
          >
            <h2 className="modalTitle" id="child-modal-title">
              Change Password
            </h2>
            <form onSubmit={handleSubmit} className="modalForm">
              <div className="modalFormItem">
                <label className="label-bold">E-Mail</label>
                <TextField
                  name="email"
                  placeholder="E-mail"
                  value={email}
                  required
                  fullWidth
                />
              </div>
              <div className="modalFormItem">
                <label className="label-bold">Old Password</label>
                <TextField
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                />
              </div>
              <div className="modalFormItem">
                <label className="label-bold">New Password</label>
                <TextField
                  name="resetPassword"
                  type="password"
                  placeholder="Reset Password"
                  value={formData.resetPassword}
                  onChange={handleChange}
                  fullWidth
                />
              </div>

              <div className="modalActions">
                <Button className="modalButton" onClick={handleSubmit}>
                  Change
                </Button>
                <Button className="modalButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUserDetails: state?.auth.user,
});

const mapDispatchToProps = {
  Userprofile,
  changePassword,
  uploadPicture,
  updateUserProfileDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersProfile);
