import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
  Modal,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import { getAllOrgAdmins, getAllOrgOrgs } from "../../services/admin.service";
import { modalPopUpStyle } from "../../styles/getLPTheme";
import { sendInviteAPI } from "../../services/auth.service";

export const OrgAdmins = () => {
  const navigate = useNavigate(); // Hook for navigation
  const [openModal, setOpenModal] = useState(false);
  const [adminsData, setAdminsData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [inviteFormData, setInviteFormData] = useState({});
  const [paginationModals, setPaginationModals] = useState({
    page: 1, // Ensure page always starts at 1
    pageSize: 10,
  });
  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;

  const columns = [
    {
      field: "mu_name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "mu_org_name",
      headerName: "Organization Name",
      flex: 1,
    },
    {
      field: "mu_role",
      headerName: "Role",
      flex: 1,
    },
  ];

  useEffect(() => {
    const fetchAdminsData = async () => {
      if (paginationModals.page > 0) { // Prevent API call if page is 0
        const adData = await getAllOrgAdmins(paginationModals.page, paginationModals.pageSize);
        setAdminsData(adData);
      }
    };
    fetchAdminsData();
  }, [paginationModals.page, paginationModals.pageSize]);

  useEffect(() => {
    const fetchOrgData = async () => {
      const oData = await getAllOrgOrgs();
      setOrgData(oData);
    };
    fetchOrgData();
  }, []);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setInviteFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (event) => {
    setInviteFormData((prevData) => ({
      ...prevData,
      organization: event.target.value,
    }));
  };

  const handleSendInvite = async () => {
    setOpenModal(false);
    await sendInviteAPI(inviteFormData);
  };

  const isFormValid = (data) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailPattern.test(data.emails || "");
    const isValidOrg = !!data.organization;
    return isValidEmail && isValidOrg;
  };

  return (
    <Card raised sx={{ minWidth: "100%" }}>
      <CardContent>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h5">Organization Admins</Typography>
          <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
            Send Add Invite
          </Button>
        </div>
        <Divider />
        <div style={{ height: tableHeight, width: "100%" }}>
          <ServerPaginationGridNoRowCount
            setPaginationModals={setPaginationModals}
            paginationModals={paginationModals}
            rowsData={adminsData}
            total={adminsData.length}
            columns={columns}
            getRowId={(row) => row.mu_id} // Use 'mu_id' as the unique identifier
          />
        </div>
      </CardContent>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box className="modalContent" sx={modalPopUpStyle} component="form">
          <h2 className="modalTitle" id="child-modal-title">
            Send Invite
          </h2>
          <form className="modalForm">
            <div className="modalFormItem">
              <label className="label-bold">Email</label>
              <input
                type="text"
                name="emails"
                onChange={handleFormDataChange}
                placeholder="Enter email"
                value={inviteFormData.emails || ""}
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  marginBottom: "8px",
                }}
              />
            </div>
            <div className="modalFormItem">
              <label className="label-bold">Organization</label>
              <Select
                fullWidth
                value={inviteFormData.organization || ""}
                onChange={handleSelectChange}
                displayEmpty
                style={{ marginBottom: "8px" }}
              >
                <MenuItem value="" disabled>
                  Select Organization
                </MenuItem>
                {orgData.map((org) => (
                  <MenuItem key={org.o_internal_name} value={org.o_internal_name}>
                    {org.o_name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="modalActions">
              <Button
                onClick={handleSendInvite}
                className="modalButton"
                disabled={!isFormValid(inviteFormData)}
              >
                Send
              </Button>
              <Button className="modalButton" onClick={() => setOpenModal(false)}>
                Cancel
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </Card>
  );
};

export default OrgAdmins;
