import {
  GET_ALL_LIST_JOB_SUCCESS,
  GET_JOB_SUCCESS,
  GET_JOBS_SUCCESS,
  APPLY_JOB_SUCCESS,
  CREATE_JOB_SUCCESS,
  DELETE_JOB_SUCCESS,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_STATUS_SUCCESS,
  UPLOAD_CVS_JOB_SUCCESS,
  UPDATE_JOB_SKILLS_SUCCESS,
  CLEAR_ADD_JOB_DATA,
  GET_HR_DASH_DATA_SUCCESS,
  PARSE_JD_SUCCESS,
  GET_INTERVIEWS_SUCCESS,
  GET_EMPLOYEES_SUCCESS,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_LIST_COURSES,
  CREATE_AUDIO_VIDEO,
  CLEAR_PARSED_JD,
  CLEAR_CREATED_JD,
  CLEAR_BATCH_DATA,
  GET_HR_DASH_DATA_PERMISSION_SUCCESS,
} from "./types";
import {
  _getAllList,
  _getJobById,
  _applyJob,
  _createJob,
  _updateJob,
  _deleteJob,
  _updateJobStatus,
  _getAll,
  _updateJobSkills,
  _uploadCVs,
  _getAllBatches,
  _getHrData,
  _parseJD,
  _getEmployees,
  _getInterviews,
  _getAllJobs,
  _getCourseList,
  _createAudioVideo,
} from "../../services/hr.service";
import { showLoading, hideLoading } from "./loader";
import { setMessage } from "./message";

export const getHrData = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getHrData();

    if (response.status === 200) {
      dispatch({
        type: GET_HR_DASH_DATA_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: GET_HR_DASH_DATA_PERMISSION_SUCCESS,
        payload: response.data.permissions,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting data", error);
    dispatch(hideLoading());
  }
};


export const getInterviews = (page, size) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getInterviews(page, size);

    if (response.status === 200) {
      dispatch({
        type: GET_INTERVIEWS_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting interviews", error);
    dispatch(hideLoading());
  }
};
export const getEmployees = (page, size) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getEmployees(page, size);
    // console.log(response);

    if (response.status === 200) {
      dispatch({
        type: GET_EMPLOYEES_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting employees", error);
    dispatch(hideLoading());
  }
};

