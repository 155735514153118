import { useState, useEffect } from "react";
import { Typography, Card, CardContent, Divider, Stack, Button, Box, Grid, TextField, } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
// import { getQuestion } from '../../redux/actions/questions';
import { Link, useParams } from "react-router-dom";
import { saveQuestionAction } from "../../redux/actions/questions";


export const CreateQuestion = ({ saveQuestionAction }) => {

    const { t, i18n } = useTranslation();
    const { id: qs_id } = useParams();
    const [formData, setFormData] = useState({});
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        saveQuestionAction(qs_id, formData)
        // console.log("Form submitted with data: ", formData);
    };

    return (
        <>
            <Card raised sx={{ minWidth: "100%" }}>
                <CardContent>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h5"> {t('Create Question')}</Typography>
                        {/* <Button
                            variant="text"
                            size={"small"}
                            sx={{ color: "green" }}
                        >
                            <AddIcon /> Save
                        </Button> */}
                    </Box>
                    <Divider />
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Name:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth
                                name="questionName"
                                placeholder="Question"
                                value={formData.setName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" onClick={handleSubmit}
                                component="label"
                                variant="contained">Save</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    saveQuestionAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestion);

