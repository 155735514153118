import React, { useState, useEffect } from "react";
import {
  Stack,
  Card,
  CardContent,
  Typography,
  Divider,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { styled, alpha } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  _getCreditCharges,
  _deleteCreditCharge,
} from "../../services/credit_charges.service";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AlertDialog from "../../components/Dialog";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const CreditChargesList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [ccId, setCcId] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [creditChargesList, setCreditChargesList] = useState({ rows: [], count: 0 });
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 50,
  });

  const fetchCreditCharges = async () => {
    try {
      const response = await _getCreditCharges(paginationModals.page, paginationModals.pageSize);
      setCreditChargesList({ rows: response.data.rows, count: response.data.count });
    } catch (error) {
      console.error("Error fetching credit charges:", error);
    }
  };

  const handleDelete = (id) => {
    setCcId(id);
    setTitle("Delete Credit Charge");
    setContent(`Are you sure you want to delete this credit charge: ${id}?`);
    setOpen(true);
  };

  const deleteCreditCharge = async () => {
    try {
      await _deleteCreditCharge(ccId);
      fetchCreditCharges();
    } catch (error) {
      console.error("Error deleting credit charge:", error);
    }
  };

  useEffect(() => {
    if (confirm) {
      deleteCreditCharge();
      setConfirm(false);
    }
  }, [confirm]);

  useEffect(() => {
    fetchCreditCharges();
  }, [paginationModals]);

  const columns = [
    {
      field: "cc_id",
      headerName: "Charge ID",
      flex: 1,
    },
    {
      field: "cc_org_id",
      headerName: "Organization ID",
      flex: 1,
    },
    {
      field: "cc_cdate",
      headerName: "Creation Date",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row.cc_id)}>
          <DeleteForeverIcon sx={{ color: "red" }} />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title={title}
        setConfirm={setConfirm}
        content={content}
      />
      <Card raised sx={{ minWidth: "100%" }}>
        <CardContent>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{ justifyContent: "space-between" }}
          >
            <Typography variant="h5">Credit Charges</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/admin/credit-charge/new")}
              >
                Add Credit Charge
              </Button>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchKey || ""}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </Search>
              {searchKey && (
                <IconButton onClick={() => setSearchKey("")}>
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
          </Stack>
          <Divider />
          <div style={{ height: 400, width: "100%" }}>
            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              getRowId={(row) => row.cc_id}
              paginationModals={paginationModals}
              rowsData={creditChargesList.rows}
              total={creditChargesList.count}
              columns={columns}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default CreditChargesList;
