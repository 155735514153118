import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useSpeechSynthesis } from "react-speech-kit";
import {
  Stack,
  Card,
  Typography,
  CardHeader,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Box,
  Modal,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import WebcamVideo from "../../components/Recorder/VideoRecorder";
import {
  startInterview,
  concludeInt,
  submitAIanswer,
  giveConsent,
  saveInterviewSession
} from "../../redux/actions/candidate";
import { getApplicant } from "../../redux/actions/applicant";
import AlertDialog from "../../components/Dialog";
import { useNavigate } from "react-router-dom";
import { Userprofile } from "../../redux/actions/auth";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import useExitPrompt from "../../hooks/exitPropmts";
import { toast } from "react-toastify";
import { hideSidebar, showSidebar } from "../../redux/actions/sidebar";
import useFullscreen from "../../hooks/fullScreenWindow";

import { _startInterview, _submitAnswer, _concludeInterview } from "../../services/leads.service";
// import NetworkSpeedTester from "../../components/NetwortSpeed";
import LipSyncAvatar from "../../components/Avtar"; // Adjust the path as needed
// Modal popup styles
const modalPopUpStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '8px',
  textAlign: 'center',
};

export const InterviewDemo = ({
  userId,
  submitAIanswer,
  Userprofile,
  currentUserDetails,
  startInterview,
  interviewEnded,
  giveConsent,
  saveInterviewSession,
  getApplicant,
  hideSidebar,
  showSidebar,
  dashboard: { submitIntAnsResRetry },
  InterViewStage,
  interViewThreadId,
  streamRef
}) => {
  const navigate = useNavigate();
  const [chatThread, setChatThread] = useState([]);
  const [response, setResponse] = useState({});
  const [threadId, setThreadId] = useState(null);
  const [intwId, setIntwId] = useState(null);
  const [assistId, setAssistId] = useState(null);
  const [vectId, setVectId] = useState(null);
  const [qaId, setQaId] = useState(null);
  const [questionCount, setQuestionCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  // const [userId, setUserId] = useState("");
  const [startInt, setStartInt] = useState(false);
  const [audio, setAudio] = useState(null);
  const [mute, setMute] = useState(false);
  const [showConsentPopup, setShowConsentPopup] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [interviewStarted, setInterviewStarted] = useState(false); // State to track if the interview is started
  const [conclusionAttempted, setConclusionAttempted] = useState(false); // State to track if interview conclusion was attempted
  const [showConcludeDialog, setShowConcludeDialog] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const { isFullScreen, enterFullScreen } = useFullscreen();
  const [startCapturing, setStartCapturing] = useState(false);
  const [transcriptData, setTranscriptData] = useState("");
  const [interviewConclude, setInterviewConclude] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [resumeInterView, setResumeInterView] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [speaking, setSpeaking] = useState(false);
  const [violationData, setViolationData] = useState("");
  const [nextQestion, setNextQuestion] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showWaitMessage, setshowWaitMessage] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false); // State to control loader visibility
  const [avatarData, setAvatarData] = useState(null);
  const [interviewData, setInterviewData] = useState(null);
  const [submitAnsRes, setSubmitAnsRes] = useState(null);
  const currentDate = new Date().toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  const { speak } = useSpeechSynthesis({
    onStart:()=>{
      // console.log("Helooooooooooooo");
      setSpeaking(true);
    },
    onEnd: () => {
      // console.log("Speech has stopped.");
      setSpeaking(false);
      setshowWaitMessage(false);
      // setStartCapturing(true);  // Start recording once speech stops
      if (interviewConclude) {
        setShowConcludeDialog(true);
      }
    },
  });

  useEffect(() => {
    const fetchVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      console.log("DDDDDD=======>",availableVoices);
      setVoices(availableVoices);
      if (availableVoices.length) {
        // Set default to the first female voice if available
        const defaultVoice = availableVoices.find((v) =>
          v.name.toLowerCase().includes("female")
        );
        console.log("Default=====>", defaultVoice);
        setSelectedVoice(defaultVoice || availableVoices[0]);
      }
    };

    // Fetch voices when they become available
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = fetchVoices;
    }
    fetchVoices();
  }, []);
const [interviewEndStatus, setInterviewEndStatus] = useState(false);

useEffect(() => {
  if(interviewEndStatus){
    setConfirm(true); 
  }
},[interviewEndStatus]);



  
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const checkSpeachEnd = localStorage.getItem("speakEnd");
  //     if (checkSpeachEnd === "true") {
  //       console.log("Speech has stopped.");
  //       setStartCapturing(true); // Start recording once speech stops
  //       if (interviewConclude) {
  //         setMute(true);
  //         setShowConcludeDialog(true);
  //         localStorage.removeItem("interviewStarted");
  //         localStorage.removeItem("speakEnd");
  //         localStorage.removeItem("questiontext");
  //       }
  //     }
  //   }, 500); // Check every 500ms
  
  //   // Cleanup
  //   return () => clearInterval(interval);
  // }, [interviewConclude]);

  const confirmConclude = async () => {
    setShowConcludeDialog(false);
    window.speechSynthesis.cancel();
    setIsLoaderVisible(true);
    concludeInterview();
    // setTimeout(() => {
    //     // Auto call after 10 seconds
    // }, 10000);
  };

  useEffect(() => {
    if (interviewEnded) {
      setInterviewStarted(false); // Reset flag when the interview ends
      setOpen(false);
      setConfirm(false);
      // navigate("/dashboard");
      // cleanupResources();
      window.location.reload();
    }
  }, [interviewEnded]);

  useEffect(() => {
    if (response && response.question && !mute) {
      console.log("Speak Should Work");
      setshowWaitMessage(true);
      setStartCapturing(true); // Stop capturing while the system is speaking
      speak({ text: response.question });
      setSpeaking(true);
      setIsLoaderVisible(false);
    }
  }, [response, mute]);

  // Handle the starting of the interview
  const initiateInterview = async () => { 
    if (!interviewStarted) { // Only start interview if it hasn't been started
      try {
        if (resumeInterView) {
          // await startInterview(currentUserDetails.u_apct_id_fk, interViewThreadId);
        } else {
          console.log("Helloooooo");
          const linterviewData = await _startInterview({id:userId});
          setInterviewData(linterviewData)
        }
        setInterviewStarted(true); // Set flag to true after successful API call
      } catch (error) {
        console.error("Failed to start the interview", error);
        setInterviewStarted(false); // Reset flag if there was an error
      }
    }
  };

  const handleStartButtonClick = async () => {
    localStorage.setItem("interviewStarted", "true");
    enterFullScreen();
    setStartInt(true);
  };
  const handleResumeButtonClick = async () => {
    localStorage.setItem("interviewStarted", "true");
    enterFullScreen();
    setStartInt(true);
    setResumeInterView(true);
  };

  useEffect(() => {
    if (startInt && !interviewStarted) {
      setShowConsentPopup(true);
      // if (!userConsent) {
      //   setShowConsentPopup(true); // Show consent popup if consent is not given
      // } else {
      //   initiateInterview(); // Start the interview directly if consent is already given
      // }
    }
  }, [startInt]);

  const showErrorModal = (message) => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    enterFullScreen();
  };

  useEffect(() => {
    if (!isFullScreen) {
      // saveInterviewSession({ isFullScreen, apct_id: currentUserDetails.u_apct_id_fk })
      showErrorModal("Full screen exit not allowed");
    }
  }, [isFullScreen]);

  useEffect(() => {
    let timer = setTimeout(() => Userprofile(), 1000); // Adjust timeout duration as needed
    return () => {
      clearTimeout(timer);
    };
  }, [Userprofile]);

  useEffect(() => {
    hideSidebar();
  }, [hideSidebar]);

  // useEffect(() => {
  //   if (currentUserDetails) {
  //     setUserId(currentUserDetails?.u_id);
  //   }
  // }, [currentUserDetails]);

  useEffect(() => {
    if (interviewData) {
      setThreadId(interviewData.thread_id);
      setIntwId(interviewData.intw_id);
      setAssistId(interviewData.assistant_id);
      setVectId(interviewData.vector_store_id);
      setQaId(interviewData.intqa_id);
      setQuestionCount(1);
      if (resumeInterView) {
        setQuestionCount(interviewData.questionsCount);
        setChatThread(interviewData.QAs);
      }
      setTimeLeft(interviewData.time * 60);
      
      setResponse({
        id: uuidv4(),
        question: interviewData.question,
        answer: "",
      });
      localStorage.setItem("questiontext",interviewData.question);
    }
  }, [interviewData]);

  useEffect(() => {
    if (submitAnsRes) {
      setThreadId(submitAnsRes.thread_id);
      setIntwId(submitAnsRes.intw_id);
      setAssistId(submitAnsRes.assistant_id);
      setVectId(submitAnsRes.vector_store_id);
      setQaId(submitAnsRes.intqa_id);
      setTimeLeft(submitAnsRes.responseTime * 60);
      setQuestionCount(submitAnsRes.questionsCount);
      setResponse({
        id: uuidv4(),
        question: submitAnsRes.response,
        answer: submitAnsRes.transcription,
      });
      setChatThread(submitAnsRes.QAs);
      setInterviewConclude(submitAnsRes.isLast);
      setNextQuestion(false);
      localStorage.setItem("questiontext",submitAnsRes.response);
    }
  }, [submitAnsRes]);

  const submitData = () => {
    setTitle("Conclude Interview");
    setContent("Are you sure you want to conclude this interview?");
    setOpen(true);
  };

  useEffect(() => {
    if (confirm && !conclusionAttempted) { // Only attempt if not already tried
      window.speechSynthesis.cancel();
      if(!interviewEndStatus) {
        setNextQuestion(true);
      }
      setTimeout(() => {
        concludeInterview();
      }, 3000);
    }
  }, [confirm]);

  const concludeInterview = async () => {
    try {
      window.speechSynthesis.cancel();
      setMute(true);
      await _concludeInterview({
        intwId,
        threadId,
        apct_id: userId,
        violationData:violationData,
      });
      setConclusionAttempted(true); // Set flag to true after successful conclusion
      // setIsLoaderVisible(false);
      // cleanupResources();
      // showSidebar();
    } catch (error) {
      window.speechSynthesis.cancel();
      console.error("Failed to conclude the interview", error);
      setConfirm(false);
      setConclusionAttempted(false); // Allow retry on failure
    }
  };

  useEffect(() => {
    if (audio) {
      const sAnswer = async () => {
        const data = {
          audio_id: audio,
          thread_id: threadId,
          intw_id: intwId,
          assistant_id: assistId,
          vector_store_id: vectId,
          intqa_id: qaId,
          confirm:confirm
        };
  
        let ansSubmit = await _submitAnswer(data);
        setSubmitAnsRes(ansSubmit);
        setAudio(null);
      }
      sAnswer();
    }
  }, [audio, submitAIanswer, threadId, intwId, assistId, vectId, qaId, submitIntAnsResRetry]);

  const handleConsentChange = (event) => {
    setConsentGiven(event.target.checked);
  };

  const handleConsentApproval = async () => {
    if (consentGiven) {
      await requestMediaPermissions();
      setShowConsentPopup(false);
    }
  };

  const requestMediaPermissions = async () => {
    try {
      // Retrieve the selected device ID from localStorage
      const selectedDeviceId = localStorage.getItem('selectedDeviceId');
      const selectedDeviceIdAudio = localStorage.getItem('selectedDeviceIdAudio');
      // Build constraints with the selected device ID for video input
      const constraints = {
        video: {
          deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
        },
        audio: selectedDeviceIdAudio? { deviceId: { exact: selectedDeviceIdAudio } } : undefined, // Enable audio
      };

  
      // Request media permissions
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      
      // Store the media stream in state
      setMediaStream(stream);
  
      // Execute additional async tasks in order
      // await giveConsent(currentUserDetails.u_apct_id_fk);
      // await getApplicant(currentUserDetails.u_apct_id_fk);
      
      // Start the interview
      initiateInterview();
    } catch (error) {
      console.error("Media permissions denied", error);
      toast.error("Media permissions are required to proceed with the interview.");
    }
  };

  // const requestMediaPermissions = async () => {
  //   try {
  //     //const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
  //     setMediaStream(streamRef.current); // Store the media stream in state
  //     await giveConsent(currentUserDetails.u_apct_id_fk);
  //     await getApplicant(currentUserDetails.u_apct_id_fk);
  //     initiateInterview(); // Start the interview after giving consent
  //   } catch (error) {
  //     console.error("Media permissions denied", error);
  //     toast.error("Media permissions are required to proceed with the interview.");
  //   }
  // };

  // Function to stop all media tracks (camera and microphone) and exit fullscreen
  const cleanupResources = () => {
    stopMediaTracks();
    exitFullscreen();
  };

  // Function to stop all media tracks (camera and microphone)
  const stopMediaTracks = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null); // Clear the media stream from state
    }
  };

  // Function to exit fullscreen mode
  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error("Error exiting fullscreen:", err);
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.key === 'F12' ||
        (event.altKey && event.key === 'F4') ||
        event.key === 'Escape'
      ) {
        event.preventDefault();
        toast.error("Invalid Action");
      }

      if (event.ctrlKey && (event.key === 'c' || event.key === 'x' || event.key === 'v')) {
        event.preventDefault();
        const actions = { c: 'Copying', x: 'Cutting', v: 'Pasting' };
        toast.error(`${actions[event.key]} is not allowed`);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleTranscript = (transcript) => {
    setTranscriptData(transcript); // Save the transcript data in the state
  };


   // Convert minutes to seconds

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000); // Decrease time every second

      return () => clearInterval(timer); // Cleanup on unmount or index change
    } 
  }, [timeLeft]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Add custom logic here to detect hard reload
      window.speechSynthesis.cancel();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Stack sx={{ display: "flex", flexDirection: "column" }}>
      {isLoaderVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "black", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensure it overlays all other elements
          }}
        >
          <Typography style={{ color: "white", fontSize: "24px" }}>
            Processing, please wait...
          </Typography>
        </div>
      )}
      <Dialog open={showConsentPopup} onClose={() => setShowConsentPopup(false)}>
        <DialogTitle>Consent Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To proceed, we need your consent to capture audio and video for the interview process.
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={consentGiven}
                onChange={handleConsentChange}
                name="consent"
                color="primary"
              />
            }
            label="We would like to capture your audio and video for this session.
                By clicking 'I Consent,' you agree to allow us to capture and use your audio and video as needed."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConsentApproval} disabled={!consentGiven} color="primary">
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      {/* Error Modal */}
      <Modal
        open={openErrorModal}
        onClose={() => { }}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="error-modal-title">
            Error
          </Typography>
          <Typography sx={{ mt: 2 }}>{errorMessage}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseErrorModal}
            sx={{ mt: 3 }}
          >
            OK
          </Button>
        </Box>
      </Modal>
      <AlertDialog open={open} setOpen={setOpen} title={title} setConfirm={setConfirm} content={content} />

      <Stack sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="subtitle2" textAlign="center" color="primary">Welcome to</Typography>
        <Typography variant="h4" textAlign="center" color="primary">AI Interview Assistant</Typography>
        {!threadId && <Typography variant="caption" color="green" sx={{ mt: 1, fontSize:"16px" }}>
              * Please before start <a href="/help.pdf" target="_blank">click here</a> to understand the interview instructions for help.
        </Typography>}
        {threadId && (
          <>
          <div style={{display:"flex"}}>
            <Button style={{ marginTop: "10px", width: "10vw", textAlign: "center", marginRight:"5px", background:"red", color:"white"}} variant="contained" onClick={submitData} disabled={!threadId}>
              End Test
            </Button>
            <Button  onClick={()=>setNextQuestion(true)} style={{ marginTop: "10px", width: "10vw", textAlign: "center", marginLeft:"5px"}} variant="contained" color="primary" autoFocus>
              Next Question
            </Button>
          </div>
          <Typography variant="caption" color="green" sx={{ mt: 1 }}>
              * Please conclude interview before leaving the page or it will auto conclude when interview is done 
            </Typography>
          </>
        )}
      </Stack>

      {threadId ? (
        <Stack sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 3 }}>
          <Card sx={{ width: "50%" }}>
            <Typography textAlign="center"></Typography>
            <WebcamVideo streamRef={streamRef} threadId={threadId} userId={userId} setAudio={setAudio} qaId={qaId} intwId={intwId} startCapturing={startCapturing} onTranscriptUpdate={handleTranscript} timeLeft={timeLeft} setConfirm={setConfirm} setViolationData={setViolationData} nextQestion={nextQestion} setStartCapturing={setStartCapturing} showWaitMessage={showWaitMessage} setIsLoaderVisible={setIsLoaderVisible} />
          </Card>
          <Card sx={{ width: "50%" }}>
            <CardHeader />
            <CardContent>
              {response.question && (
                <>
                 {avatarData && <LipSyncAvatar speechText={response.question} speaking={speaking} avatarData={avatarData}/>}
                  <Typography style={{ display: 'flex', justifyContent: 'space-between', marginTop:"5%"}}>
                    {/* <IconButton onClick={() => setMute(!mute)}>
                      {mute ? <VolumeOffIcon /> : <VolumeUpIcon />} :
                    </IconButton> */}
                    <span>{response.question}</span>
                    {timeLeft > 0 && <span>{formatTime(timeLeft)}</span>}
                    
                  </Typography>
                  {/* <Typography>{transcriptData}</Typography> */}
                  
                </>
              )}
            </CardContent>
          </Card>
        </Stack>
      ) : (
        <>
        <Stack sx={{ mt: 3, display: "flex", flexDirection: "row", justifyContent: "center", width:"100%" }}>
          {InterViewStage === "Partial"?<Button variant="contained" onClick={handleResumeButtonClick}>Resume Interview</Button>:<Button variant="contained" onClick={handleStartButtonClick}>Start</Button>}
        </Stack>
        <Stack>
        <Box sx={{ mt: 3, p: 2, border: "1px solid #ccc", borderRadius: "8px" }}>
          <Typography variant="h6" gutterBottom>
            Prepare your Computer System for Online Interview or Exam
          </Typography>
          <Typography variant="body2" gutterBottom>
            Please note that this link will expire 30 minutes after the email is received to avoid any confusion with the actual test link.
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>ONLINE REMOTE PROCTORED EXAMINATION PROTOCOL</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            <ul>
              <li>Ensure you have a working computer system with a webcam, mic, and stable internet connection.</li>
              <li>Browsers supported: Google Chrome, Firefox, Safari (Apple).</li>
              <li>Exam Duration: 15 minutes.</li>
              <li>The exam is monitored by a live proctor and an AI tool.</li>
              <li>Avoid suspicious activities; warnings may lead to penalties.</li>
              <li>Maintain a quiet environment and sufficient lighting.</li>
              <li>Do not browse, use devices, or read aloud during the test.</li>
              <li>Exiting fullscreen or pressing "F5" ends the test abruptly.</li>
            </ul>
          </Typography>
        </Box>
        </Stack>
        </>
      )}

      {chatThread.length > 0 && (
        <Stack sx={{ mt: 5, ml: 3, display: "flex", flexDirection: "column" }}>
          <Typography variant="h6">AI Post Interview Analysis</Typography>
          {chatThread.map((res) => (
            res.intqa_answer && (
              <Card key={res.intqa_id} sx={{ minWidth: "30vw" }}>
                <CardContent>
                  <Typography variant="h6">🤖 : {res.intqa_question}</Typography>
                  <Typography>🧑 : {res.intqa_answer}</Typography>
                </CardContent>
              </Card>
            )
          ))}
        </Stack>
      )}
      <Dialog
        open={showConcludeDialog}
        onClose={() => setShowConcludeDialog(false)}
      >
        <DialogTitle>{"Concluding Interview"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please wait, we are concluding the interview.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmConclude} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  currentUserDetails: state.auth.user,
  interviewEndTime: state.candidate.interviewEndTime,
  submitAnsRes: state.candidate.submitAnsRes,
  interviewEnded: state.candidate.interviewEnded,
  userConsent: state.applicant?.applicant?.applicant?.apct_user_consent,
});

const mapDispatchToProps = {
  startInterview,
  submitAIanswer,
  Userprofile,
  giveConsent,
  getApplicant,
  hideSidebar,
  showSidebar,
  saveInterviewSession
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewDemo);
