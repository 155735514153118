import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from 'recharts';
import { getAllUsedOrgs, getAllOrgOrgs } from '../../services/admin.service';

const AdminDashboard = () => {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#845EC2', '#D93655'];

  const [selectedOrg, setSelectedOrg] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [tokenTypeData, setTokenTypeData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [orgData, setOrgData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [dateFilter, setDateFilter] = useState(() => {
    const currentDate = new Date();
    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - 10);
  
    return {
      startDate: startDate.toISOString().split('T')[0],
      endDate: currentDate.toISOString().split('T')[0],
    };
  });

  const fetchUsedData = async () => {
    try {
      const orgAllData = await getAllUsedOrgs(selectedOrg);
      const totalCredits = orgAllData.total_credits;
      const totalRemaining = orgAllData.remaining_credits;
      const dataForOrg = orgAllData.data;

      // Combine data based on date and tokenType
      const combinedData = dataForOrg.reduce((acc, curr) => {
        const existing = acc.find(item => item.date === curr.date);
        if (existing) {
          existing[curr.tokenType] = (existing[curr.tokenType] || 0) + curr.used;
        } else {
          acc.push({ date: curr.date, [curr.tokenType]: curr.used });
        }
        return acc;
      }, []);

      // Filter data based on last 5 days or selected date range
      const today = new Date();
      const last5Days = new Date();
      last5Days.setDate(today.getDate() - 5);
      const filteredByDate = combinedData.filter((item) => {
        const itemDate = new Date(item.date);
        if (dateFilter.startDate && dateFilter.endDate) {
          const start = new Date(dateFilter.startDate);
          const end = new Date(dateFilter.endDate);
          return itemDate >= start && itemDate <= end;
        }
        return itemDate >= last5Days && itemDate <= today;
      });

      setBarChartData(filteredByDate);

      // Aggregate token type usage
      const tokenTypeAggregates = dataForOrg.reduce((acc, curr) => {
        if (!acc[curr.tokenType]) {
          acc[curr.tokenType] = 0;
        }
        acc[curr.tokenType] += curr.used;
        return acc;
      }, {});

      const tokenTypeData = Object.entries(tokenTypeAggregates).map(([tokenType, used]) => ({
        tokenType,
        used,
      }));

      // Prepare data for PieChart
      const pieData = [
        { name: 'Remaining Credits', value: totalRemaining },
        ...tokenTypeData.map((item) => ({ name: `${item.tokenType} Used`, value: item.used })),
      ];

      setFilteredData(combinedData);
      setTokenTypeData(tokenTypeData);
      setPieChartData(pieData);
      setRemainingCredits(totalRemaining);
      setTotalCredits(totalCredits);
    } catch (error) {
      console.error('Error fetching used data:', error);
    }
  };

  useEffect(() => {
    if (selectedOrg) {
      fetchUsedData();
    }
  }, [selectedOrg, dateFilter]);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const oData = await getAllOrgOrgs();
        setOrgData(oData);
        const user = JSON.parse(localStorage.getItem('user'));
        setUserData(user);
        setSelectedOrg(user?.u_role !== 'admin' ? user?.u_default_org_fk : oData[0]?.o_id);
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };

    fetchOrgData();
  }, []);

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Token Usage Dashboard
      </Typography>

      <Grid container spacing={4}>
        {userData?.u_role === 'admin' && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Select Organization</InputLabel>
              <Select
                value={selectedOrg}
                onChange={(e) => setSelectedOrg(e.target.value)}
                label="Select Organization"
              >
                {orgData.map((item) => (
                  <MenuItem key={item.o_id} value={item.o_id}>
                    {item.o_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={dateFilter.startDate}
            onChange={(e) => setDateFilter({ ...dateFilter, startDate: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={dateFilter.endDate}
            onChange={(e) => setDateFilter({ ...dateFilter, endDate: e.target.value })}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="primary">
                Total Credits
              </Typography>
              <Typography>{totalCredits}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="primary">
                Remaining Credits
              </Typography>
              <Typography>{remainingCredits}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {tokenTypeData.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6" color="primary">
                  {item.tokenType.replace('_', ' ').toUpperCase()}
                </Typography>
                <Typography>Total Used: {item.used}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Token Usage by Date and Type</Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={barChartData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  {Object.keys(barChartData[0] || {}).filter(key => key !== 'date').map((key, index) => (
                    <Bar
                      key={index}
                      dataKey={key}
                      name={key.replace('_', ' ').toUpperCase()}
                      stackId="a"
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Token Usage and Remaining Credits</Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={pieChartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    fill="#8884d8"
                    label={({ name, value }) => `${name}: ${value}`}
                  >
                    {pieChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
