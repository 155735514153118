import React, { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
} from "@mui/material";
import SendInviteDialog from "../common/SendInviteDialog"; // Import the new component

import { Link } from "react-router-dom";
import {
  sendInvite,
  addHrRemarks,
  getCandidatesByBatchId,
  releaseOffer,
  scheduleSecRound,
} from "../../redux/actions/candidate";
import { formatExperienceDuration } from "../../utils/helpers";
import FlagIcon from "@mui/icons-material/Flag";
// Import Material-UI icons
import SmartDisplayTwoToneIcon from "@mui/icons-material/SmartDisplayTwoTone";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import DownloadIcon from "@mui/icons-material/Download";
import BookIcon from "@mui/icons-material/Book";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/system";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import ViolationSliderModal from '../../components/modals/ViolationSliderModal';
import {
  getApplicantReport,
  viewApplicantReport,
} from "../../redux/actions/applicant";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { formatDate } from "../../utils/dateUtils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AlertDialog from "../../components/Dialog";
import extractSortAndFilterParams from "../../components/Table/filterSortParams";
import {
  _addViolation,
  _removeViolation,
} from "../../services/candidate.service";
const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 550px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

///

const CandidatesBatch = ({
  getCandidatesByBatchId,
  candidates,
  sendInvite,
  addHrRemarks,
  releaseOffer,
  getApplicantReport,
  viewApplicantReport,
  scheduleSecRound,
  bT_ID,
  relaodData,
  setRelaodData,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showViolationPopup, setShowViolationPopup] = useState(false);
  const [showRemoveViolationPopup, setShowRemoveViolationPopup] =
    useState(false);
  const [showSendInviteModal, setShowSendInviteModal] = useState(false);
  const [showPopupRemark, setShowPopupRemark] = useState(false);
  const [remarkText, setRemarkText] = useState("");
  const [violationText, setViolationText] = useState("");
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 50,
  });
  const [apctID, setApctID] = useState(null);
  const [violationApctID, setViolationApctID] = useState(null);
  const [remark, setRemark] = useState(null);
  const [showPopupSecRound, setShowPopupSecRound] = useState(false);
  const [secRoundApctId, setSecRoundApctId] = useState(null);
  const [email, setEmail] = useState(null);
  const [tableHeight, setTableHeight] = useState(400);
  const [mcqChecked, setMcqChecked] = useState(false);
  const [codeChecked, setCodeChecked] = useState(false);
  const [aiAssistanceCheck, setAiAssistanceCheck] = useState(true);
  const [emailContent, setEmailContent] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [mcqCount, setMcqCount] = useState(20);
  const [codeCount, setCodeCount] = useState(2);
  const [mcqError, setMcqError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [reschedule, setReshedule] = useState(false);
  const [contentRemove, setContentRemove] = useState("");
  const [confirmRemoveViolation, setConfirmRemoveViolation] = useState(false);
  // New state variables for time settings
  const [mcqTime, setMcqTime] = useState(15);
  const [codeTime, setCodeTime] = useState(15);
  const [aiInterviewTime, setAiInterviewTime] = useState(15);
  const [sortModels, setSortModels] = useState([]);
  const [emailSubject, setEmailSubject] = useState("");
  const [optionType, setOptionType] = useState("Standard");
  const [practicalOption, setPracticalOption] = useState("");

  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const [linkExpTime, setLinkExpTime] = useState(24);



const handleLinkExpTimeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0) {
      setLinkExpTime(value);
    }
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prev) => {
      if (prev.includes(id)) {
        return prev.filter((rowId) => rowId !== id); // Deselect row
      } else {
        return [...prev, id]; // Select row
      }
    });
  };

  // Select or deselect all rows
const handleSelectAllRows = () => {
  setSelectedRows((prev) => {
    if (prev.length === candidates.rows.length) {
      return []; // Deselect all if already all are selected
    } else {
      return candidates.rows.map((row) => row.apct_id); // Select all rows
    }
  });
};
  const handleViolationClick = (id) => {
    setViolationApctID(id);
    setShowViolationPopup(true);
  };

  const handleRemoveViolationClick = (id, name) => {
    setViolationApctID(id);
    setContentRemove(
      `Are you sure you want to remove violation of the applicant:  ${name}?`
    );
    setShowRemoveViolationPopup(true);
  };

  const [violationOpen, setViolationOpen] =useState(false);
  
  const [violationImages, setViolationImages] = useState([
    'https://via.placeholder.com/600x400?text=Image+1',
    'https://via.placeholder.com/600x400?text=Image+2',
    'https://via.placeholder.com/600x400?text=Image+3',
  ])

  // Handlers for opening and closing the modal
  const handleViolationOpen = (images, id) => {
      setViolationImages(images);
      setViolationOpen(true);    
  };
  const handleViolationClose = () => {
    setViolationOpen(false);
  }

  const columns = [
    // {
    //   field: "apct_btch_number",
    //   headerName: "Batch",
    //   width: 40,
    //   renderCell: (params) => (
    //     <Link to={`/hr/candidates?batchId=${params.row.apct_btch_id_fk}`}>
    //       {params.row.apct_btch_number}
    //     </Link>
    //   ),
    // },
    {
      field: "selection",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <Checkbox
          checked={selectedRows.includes(params.row.apct_id)}
          onChange={() => handleSelectRow(params.row.apct_id)}
        />
      ),
    },
    {
      field: "apct_name",
      headerName: "Name",
      flex: 1,
      minWidth: 250,

      renderCell: (params) => {
        const { apct_id, apct_name, apct_violation } = params.row;
        let flaggedReason;
        let multipleCount = 0;
        if (apct_violation.includes("reason")) {
          flaggedReason = JSON.parse(apct_violation).reason;
          multipleCount = JSON.parse(apct_violation).multiFace;
        }
        return (
          <Link to={`/hr/candidates/candidate-profile/${apct_id}`}>
            {apct_name}
            {(flaggedReason || multipleCount > 2) && (
              <>
                <FlagIcon
                  style={{ color: "red", marginLeft: "8px" }}
                  titleAccess="Flagged"
                />
                <Tooltip
                  title={
                    flaggedReason
                      ? flaggedReason
                      : multipleCount > 0
                      ? "Multiple face found"
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <span  style={{ color: "red", marginLeft: "4px" }}>
                    Violated User
                  </span>
                </Tooltip>
              </>
            )}
          </Link>
        );
      },
    },
    {
      field: "apct_exp",
      headerName: "Experience",
      flex: 1,
      renderCell: (params) => {
        const { apct_exp, apct_exp_status } = params.row;
        return (
          <>
            <span>
              {formatExperienceDuration(apct_exp.length === 0 ? "0" : apct_exp)}
            </span>
            {apct_exp_status === false && (
              <FlagIcon
                style={{ color: "red", marginLeft: "8px" }}
                titleAccess="Flagged"
              />
            )}
          </>
        );
      },
    },
    {
      field: "apct_match_rate",
      headerName: "CV Match (%)",
      flex: 1,
      renderCell: (params) => (
        <>{params.row.apct_match_rate?.replace("%", "")}</>
      ), // Remove percentage sign
      sortComparator: (v1, v2) => {
        const val1 = parseFloat(v1.replace("%", "")); // Parse without "%", safer if renderCell already stripped it
        const val2 = parseFloat(v2.replace("%", ""));
        return val1 - val2;
      },
    },
    {
      field: "apct_int_url_sent",
      headerName: "Invite",
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() =>
            params.row.apct_int_url_sent
              ? openResheduleSendInviteModal(params.row.apct_id)
              : openSendInviteModal(params.row.apct_id)
          }
          // disabled={params.row.apct_int_url_sent}
        >
          {params.row.apct_int_url_sent ? "Reschedule" : "Send"}
        </Button>
      ),
    },

    {
      field: "apct_email_opened",
      headerName: "Email Status",
      flex: 1,
      renderCell: ({ row: { apct_int_url_sent, apct_email_opened } }) => 
        apct_int_url_sent 
          ? (apct_email_opened ? 'Email Opened' : 'Email Sent') 
          : ''
    },    
    {
      field: "intw_rating",
      headerName: "Assistant Score",
      flex: 1,
      renderCell: (params) =>
        params.row.apct_intw_rating ? (
          <Link to={`/hr/ai_score/v2/${params.row.apct_id}`}>
            {`${params.row.apct_intw_rating}/10`}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      field: "apct_violation",
      headerName: "Violation Details",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const { apct_violation, violation_images, apct_id } = params.row;
        let flagged = apct_violation;

        try {
          if (
            typeof apct_violation === "string" &&
            apct_violation.includes("reason")
          ) {
            flagged = JSON.parse(apct_violation);
          }
        } catch (error) {
          console.error("Error parsing apct_violation:", error);
        }

        // if (flagged) return null;
        if (typeof flagged === "string") return null;
        // Prepare tooltip content
        const tooltipContent = (
          <div>
            {flagged.noFace >= 0 && <div>No Face: {flagged.noFace}</div>}
            {flagged.multiFace >= 0 && (
              <div>Multiple Faces: {flagged.multiFace}</div>
            )}
            {flagged.lookingAway >= 0 && (
              <div>Looking Away: {flagged.lookingAway}</div>
            )}
          </div>
        );

        const handleClick = () => {
          if (violation_images?.length > 0) {
            handleViolationOpen(violation_images, apct_id);
          }
        };

        return (
          <Tooltip title={tooltipContent} placement="top" arrow>
            <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={handleClick} >
              View Details
            </span>
          </Tooltip>
        );
      },
    },
    // {
    //   field: "apct_second_round",
    //   headerName: "2nd Round",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Button onClick={() => setSecRoundApctId(params.row.apct_id)}>
    //       {params.row.apct_second_round ? "Scheduled" : "Schedule"}
    //     </Button>
    //   ),
    // },
    {
      field: "apct_hr_remarks",
      headerName: "Remark",
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={
            params.row.apct_hr_remarks
              ? () => setRemark(params.row.apct_hr_remarks)
              : () => setApctID(params.row.apct_id)
          }
        >
          {params.row.apct_hr_remarks ? "View" : "Add"}
        </Button>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.apct_stage === "completed" ||
          params.row.apct_stage === "Partial" ? (
            <Link
              to={`/hr/candidates/interview-details/${params.row.apct_user_id_fk}/${params.row.apct_int_thread_id}`}
            >
              <IconButton sx={{ color: "#f70000" }}>
                <SmartDisplayTwoToneIcon />
              </IconButton>
            </Link>
          ) : (
            <IconButton disabled>
              <SmartDisplayTwoToneIcon />
            </IconButton>
          )}
          <IconButton
            disabled={!params.row.apct_intw_rating}
            onClick={() => setApctID(params.row.apct_id)}
          >
            <VolumeUpIcon />
          </IconButton>
          <IconButton
            disabled={!params.row.apct_intw_rating}
            onClick={() => viewApplicantReport(params.row.apct_id)}
          >
            <SpeakerNotesIcon />
          </IconButton>
          <IconButton
            disabled={!params.row.apct_intw_rating}
            onClick={() => getApplicantReport(params.row.apct_id)}
          >
            <DownloadIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: "violation_actions",
      headerName: "Violation",
      flex: 1,
      renderCell: (params) => {
        const { apct_violation } = params.row;
        let flaggedReason;
        if (apct_violation.includes("reason")) {
          flaggedReason = JSON.parse(apct_violation).reason;
        }
        return (
          <>
            <IconButton
              onClick={() =>
                flaggedReason
                  ? handleRemoveViolationClick(
                      params.row.apct_id,
                      params.row.apct_name
                    )
                  : handleViolationClick(params.row.apct_id)
              }
            >
              <BookIcon style={{ color: flaggedReason ? "red" : "inherit" }} />
            </IconButton>
          </>
        );
      },
    },
    // {
    //   field: "apct_offer_released",
    //   headerName: "Offer",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <>
    //       <Button
    //         onClick={() => offerReleased(params.row.apct_id)}
    //         disabled={params.row.apct_offer_released}
    //       >
    //         {params.row.apct_offer_released ? "Released" : "Release"}
    //       </Button>
    //     </>
    //   ),
    // },
    {
      field: "apct_created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <>
          {params && params.row && params.row.apct_created_at
            ? formatDate(
                JSON.parse(localStorage.getItem("user")),
                params.row.apct_created_at
              )
            : ""}
        </>
      ),
    },
    {
      field: "Edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => (
        <Button
          component={Link}
          to={`/hr/candidates/candidate-profile/${params.row.apct_id}?edit=true`}
          variant="text"
          color="primary"
          size={"small"}
        >
          <EditIcon />
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (remark) {
      setShowPopupRemark(true);
    }
  }, [remark]);

  useEffect(() => {
    if (apctID) {
      setShowPopup(true);
    }
  }, [apctID]);

  useEffect(() => {
    if (secRoundApctId) {
      setShowPopupSecRound(true);
    }
  }, [secRoundApctId]);

  const scheduleSecondRound = async () => {
    await scheduleSecRound({
      apct_id: secRoundApctId,
      email: email,
    });
    setSecRoundApctId(null);
    setEmail(null);
    setShowPopupSecRound(false);
  };

  const onCancelSchedule = () => {
    setSecRoundApctId(null);
    setEmail(null);
    setShowPopupSecRound(false);
  };

  useEffect(() => {
    if (bT_ID) {
      const { sort, filters } = extractSortAndFilterParams(sortModels, []);
      getCandidatesByBatchId(
        bT_ID,
        paginationModals.page,
        paginationModals.pageSize,
        sort.field,
        sort.sort
      );
    }
  }, [bT_ID, paginationModals, sortModels]);

  useEffect(() => {
    if (relaodData) {
      const { sort, filters } = extractSortAndFilterParams(sortModels, []);
      getCandidatesByBatchId(
        bT_ID,
        paginationModals.page,
        paginationModals.pageSize,
        sort.field,
        sort.sort
      );
    }
  }, [relaodData]);

  const openSendInviteModal = (candidateId) => {
    setMcqChecked(false);
    setCodeChecked(false);
    // setEmailContent(`<p>Hi {{userName}},</p>
    //             <p>You have been invited for an AI Interview for the position of {{jobName}}.</p>
    //             <p>Please find below details:</p>
    //             <p>Username: {{userEmail}}</p>
    //             <p>Password: {{userPassword}}</p>
    //             <p>Please click on below link to start interview</p>
    //             <a href="{{link}}" target="_blank">Click Here</a>
    //             <div>Thank you</div>
    //             <div>Team</div>
    //             <div>Girikon</div>`);

    setEmailContent(` <p>Dear {{userName}},</p>
      <p>We are delighted to invite you to participate in the next round of our recruitment process for the position of <strong>{{jobName}}</strong>. As part of our selection process, you will be completing an interview assessment on an AI-powered platform.</p>
      <p><strong>Your Interview Details:</strong></p>
      <ul>
          <li><strong>Access the interview platform using the link below:</strong> <a href="{{link}}" target="_blank">Click Here</a></li>
          <li><strong>Username:</strong> {{userEmail}}</li>
          <li><strong>Password:</strong> {{userPassword}}</li>
      </ul>
      <p><strong>How to Proceed:</strong></p>
      <ol>
          <li>Use the link above to enter the interview platform.</li>
          <li>Log in with the username and password specified above.</li>
          <li>After logging in, grant consent to allow camera and microphone access.</li>
          <li>Questions will be shared on the screen, and you can answer them once the narration of the question stops.</li>
      </ol>
      <p>For the best experience, please take the interview in a quiet area with a reliable internet connection.</p>
      <p>The Interview link will expire 30 minutes after the mail is received.</p>
      <p>Regards</p>
      <p><strong>Human Resources Department</strong></p>
      <p><strong>ORIX India</strong></p>`);
    setSelectedCandidateId(candidateId);
    setShowSendInviteModal(true);
  };

  const openResheduleSendInviteModal = (candidateId) => {
    // console.log("Reshedule======>");
    setReshedule(true);
    setMcqChecked(false);
    setCodeChecked(false);
    // setEmailContent(`<p>Hi {{userName}},</p>
    //             <p>You have been invited for an AI Interview for the position of {{jobName}}.</p>
    //             <p>Please find below details:</p>
    //             <p>Username: {{userEmail}}</p>
    //             <p>Password: {{userPassword}}</p>
    //             <p>Please click on below link to start interview</p>
    //             <a href="{{link}}" target="_blank">Click Here</a>
    //             <div>Thank you</div>
    //             <div>Team</div>
    //             <div>Girikon</div>`);

    setEmailContent(` <p>Dear {{userName}},</p>
      <p>We are delighted to invite you to participate in the next round of our recruitment process for the position of <strong>{{jobName}}</strong>. As part of our selection process, you will be completing an interview assessment on an AI-powered platform.</p>
      <p><strong>Your Interview Details:</strong></p>
      <ul>
          <li><strong>Access the interview platform using the link below:</strong> <a href="{{link}}" target="_blank">Click Here</a></li>
          <li><strong>Username:</strong> {{userEmail}}</li>
          <li><strong>Password:</strong> {{userPassword}}</li>
      </ul>
      <p><strong>How to Proceed:</strong></p>
      <ol>
          <li>Use the link above to enter the interview platform.</li>
          <li>Log in with the username and password specified above.</li>
          <li>After logging in, grant consent to allow camera and microphone access.</li>
          <li>Questions will be shared on the screen, and you can answer them once the narration of the question stops.</li>
      </ol>
      <p>For the best experience, please take the interview in a quiet area with a reliable internet connection.</p>
      <p>The Interview link will expire 30 minutes after the mail is received.</p>
      <p>Regards</p>
      <p><strong>Human Resources Department</strong></p>
      <p><strong>ORIX India</strong></p>`);
    setSelectedCandidateId(candidateId);
    setShowSendInviteModal(true);
  };

  const handleCloseSendInviteModal = () => {
    setShowSendInviteModal(false);
    setSelectedCandidateId(null);
  };

  const handleRemarkSubmit = (apct_id) => {
    let data = {
      hr_remarks: remarkText,
      apct_id: apct_id,
    };
    addHrRemarks(
      data,
      null,
      bT_ID,
      paginationModals.page,
      paginationModals.pageSize
    );
    setShowPopup(false);
    setApctID(null);
  };

  const handleViolationReasonSubmit = async (apct_id) => {
    let data = {
      reason: violationText,
      apct_id: apct_id,
    };
    await _addViolation(data);
    setShowViolationPopup(false);
    setViolationApctID(null);
    setRelaodData(true);
  };

  const onClickCloseRemark = () => {
    setApctID(null);
    setShowPopup(false);
    setRemark(null);
  };

  const onClickCloseViolationReason = () => {
    setApctID(null);
    setShowViolationPopup(false);
    setViolationText(null);
  };

  const offerReleased = (apct_id) => {
    releaseOffer(
      apct_id,
      null,
      bT_ID,
      paginationModals.page,
      paginationModals.pageSize
    );
  };

  const openConfirmationDialog = () => {
    setShowConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  const confirmAndSendInvite = () => {
    // sendInviteCandidate(params.row.apct_id);
    let bodyData = {
      mcq: mcqChecked,
      coding: codeChecked,
      interview: aiAssistanceCheck,
      email_body: emailContent,
      email_subject: emailSubject,
      mcqCount: mcqCount,
      codeCount: codeCount,
      mcqTime: mcqTime,
      codeTime: codeTime,
      aiInterviewTime: aiInterviewTime,
      reschedule: reschedule,
      linkExpTime: linkExpTime,
      optionType: optionType,
      practicalOption: practicalOption
    };
    sendInvite(
      selectedCandidateId,
      null,
      bT_ID,
      paginationModals.page,
      paginationModals.pageSize,
      bodyData
    );
    setShowConfirmationDialog(false);
    setShowSendInviteModal(false);
    setSelectedCandidateId(null);
  };

  useEffect(() => {
    updateTableHeight(candidates?.rows?.length);
  }, [paginationModals.pageSize, candidates]);

  // Validation handlers
  const handleMcqCountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setMcqCount(value);
    if (value % 5 === 0 && value >= 10 && value <= 100) {      
      setMcqError(false);
    } else {
      setMcqError(true);
    }
  };

  const handleCodeCountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setCodeCount(value);
    if (value >= 2 && value <= 10) {
      setCodeError(false);
    } else {
      setCodeError(true);
    }
  };

  // Validation handlers for time settings
  const handleMcqTimeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0) {
      setMcqTime(value);
    }
  };

  const handleCodeTimeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0) {
      setCodeTime(value);
    }
  };

  const handleAiInterviewTimeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0) {
      setAiInterviewTime(value);
    }
  };
  const submitRemoveViolation = async () => {
    const data = {
      apct_id: violationApctID,
    };
    await _removeViolation(data);
    setShowRemoveViolationPopup(false);
    setViolationApctID(null);
    setRelaodData(true);
  };

  useEffect(() => {
    if (confirmRemoveViolation) {
      submitRemoveViolation();
    }
  }, [confirmRemoveViolation]);

  const deselectAllRows = () => {
    setSelectedRows([]);
  };

  return (
    <>
      <Card raised sx={{ minWidth: "100%" }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" sx={{ mt: 2 }}>
              Candidates <Button
              variant="text"
              size={"small"}
              color="primary"
              onClick={() => selectedRows.length === candidates?.rows.length ? deselectAllRows() : handleSelectAllRows()}
            >
              {selectedRows.length === candidates?.rows.length ? "Deselect All" : "Select All"}
            </Button>
            </Typography>

            <Button
              variant="text"
              size={"small"}
              color="primary"
              disabled={selectedRows.length <= 0}
              onClick={() => openSendInviteModal(selectedRows)}
            >
              Send Bulk Invite
            </Button>
          </Box>

         
          <Divider />

          {/* Use the new SendInviteDialog component */}
          <SendInviteDialog
            open={showSendInviteModal}
            handleClose={handleCloseSendInviteModal}
            confirmAndSendInvite={confirmAndSendInvite}
            openConfirmationDialog={openConfirmationDialog}
            closeConfirmationDialog={closeConfirmationDialog}
            mcqChecked={mcqChecked}
            setMcqChecked={setMcqChecked}
            codeChecked={codeChecked}
            setCodeChecked={setCodeChecked}
            aiAssistanceCheck={aiAssistanceCheck}
            setAiAssistanceCheck={setAiAssistanceCheck}
            emailContent={emailContent}
            setEmailContent={setEmailContent}
            setEmailSubject={setEmailSubject}
            mcqCount={mcqCount}
            handleMcqCountChange={handleMcqCountChange}
            mcqError={mcqError}
            codeCount={codeCount}
            handleCodeCountChange={handleCodeCountChange}
            codeError={codeError}
            mcqTime={mcqTime}
            handleMcqTimeChange={handleMcqTimeChange}
            codeTime={codeTime}
            handleCodeTimeChange={handleCodeTimeChange}
            aiInterviewTime={aiInterviewTime}
            linkExpTime={linkExpTime}
            handleAiInterviewTimeChange={handleAiInterviewTimeChange}        
            handleLinkExpTimeChange={handleLinkExpTimeChange}
            showConfirmationDialog={showConfirmationDialog}
            reschedule={reschedule}
            optionType = {optionType}
            setOptionType = {setOptionType}
            setPracticalOption = {setPracticalOption}
            practicalOption = {practicalOption}
          />
          <Dialog
            open={showPopupSecRound}
            onClose={() => onCancelSchedule()}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Schedule Second Round</DialogTitle>
            <DialogContent>
              <Typography>Enter Interviewer's Email:</Typography>
              <TextField
                type="email"
                autoFocus
                fullWidth
                minWidth="sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onCancelSchedule()}>Cancel</Button>
              <Button
                onClick={() => scheduleSecondRound()}
                color="primary"
                disabled={!email}
              >
                Schedule
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={showPopup}
            onClose={() => setShowPopup(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Add Remark</DialogTitle>
            <DialogContent>
              <Textarea
                minRows={4}
                autoFocus
                fullWidth
                minWidth="sm"
                value={remarkText}
                onChange={(e) => setRemarkText(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClickCloseRemark()}>Cancel</Button>
              <Button
                onClick={() => handleRemarkSubmit(apctID)}
                color="primary"
                disabled={!remarkText}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={showViolationPopup}
            onClose={() => setShowViolationPopup(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Add Violation Reason</DialogTitle>
            <DialogContent>
              <Textarea
                minRows={4}
                autoFocus
                fullWidth
                minWidth="sm"
                value={violationText}
                onChange={(e) => setViolationText(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClickCloseViolationReason()}>
                Cancel
              </Button>
              <Button
                onClick={() => handleViolationReasonSubmit(violationApctID)}
                color="primary"
                disabled={!violationText}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={showPopupRemark}
            onClose={() => setShowPopupRemark(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>View Remark</DialogTitle>
            <DialogContent>
              <Typography>{remark}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowPopupRemark(false)}>Okay</Button>
            </DialogActions>
          </Dialog>

          <ViolationSliderModal images={violationImages} open={violationOpen} onClose={handleViolationClose} setViolationOpen={setViolationOpen}/>

          <AlertDialog
            open={showRemoveViolationPopup}
            setOpen={setShowRemoveViolationPopup}
            title={"Remove Violation"}
            setConfirm={setConfirmRemoveViolation}
            content={contentRemove}
          />
          <div style={{ height: tableHeight, width: "100%" }}>
            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={candidates && candidates.rows ? candidates.rows : []}
              total={candidates && candidates.count ? candidates.count : 0}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: "apct_match_rate",
                      sort: "desc",
                    },
                  ],
                },
              }}
              setSortModels={setSortModels}
              sortModels={sortModels}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  candidates: state.candidate.candidateBatchLists,
  applicant_resume: state.applicant.applicant_resume,
  applicant_video: state.applicant.applicant_video,
});

const mapDispatchToProps = {
  sendInvite,
  addHrRemarks,
  getCandidatesByBatchId,
  releaseOffer,
  getApplicantReport,
  viewApplicantReport,
  scheduleSecRound,
};
export default connect(mapStateToProps, mapDispatchToProps)(CandidatesBatch);
