import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { _createCreditRecharge } from "../../services/credit_recharge.service";
import { getAllOrgOrgs } from "../../services/admin.service";

const CreditRechargeForm = () => {
  const navigate = useNavigate();
  const [orgData, setOrgData] = useState([]);
  const [formData, setFormData] = useState({
    cr_org_id: "",
    cr_credits: "",
    cr_transaction_id: "",
    cr_invoice_id: "",
    cr_payment_method: "",
    cr_status: "",
    cr_description: "",
  });

  // Predefined options for status
  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Completed", label: "Completed" },
    { value: "Failed", label: "Failed" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await _createCreditRecharge(formData);
      navigate("/admin/credit-recharges");
    } catch (error) {
      console.error("Error creating credit recharge:", error);
    }
  };

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const oData = await getAllOrgOrgs();
        setOrgData(oData);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };
    fetchOrgData();
  }, []);

  return (
    <Card raised sx={{ maxWidth: 600, mx: "auto", mt: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Add Credit Recharge
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Select Organization</InputLabel>
                <Select
                  label="Select Organization"
                  name="cr_org_id"
                  value={formData.cr_org_id}
                  onChange={handleChange}
                  required
                >
                  {orgData.map((item) => (
                    <MenuItem key={item.o_id} value={item.o_id}>
                      {item.o_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Credits"
                name="cr_credits"
                type="number"
                value={formData.cr_credits}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Transaction ID"
                name="cr_transaction_id"
                value={formData.cr_transaction_id}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Invoice ID"
                name="cr_invoice_id"
                value={formData.cr_invoice_id}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Payment Method"
                name="cr_payment_method"
                value={formData.cr_payment_method}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  name="cr_status"
                  value={formData.cr_status}
                  onChange={handleChange}
                  required
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="cr_description"
                multiline
                rows={3}
                value={formData.cr_description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreditRechargeForm;
