import { _getEmployeesProfileDetail, _updateEmployeeProfileDetail, _startInterview, _submitAns, _endInterview } from "../../services/employee.service";
import { hideLoading, showLoading } from "./loader";
import { setMessage } from "./message";
import {
  GET_EMPLOYEE_PROFILE_DETAIL,
  UPDATE_EMPLOYEE_PROFILE_DETAIL,
    START_INTERVIEW_EMP_SUCCESS,
    SUBMIT_INTERVIEW_QUESTION_EMP_SUCCESS,
    END_INTERVIEW_EMP_SUCCESS,
} from "./types";

export const getEmployeesProfileDetail = (id) => async(dispatch) => {
    try {
        // console.log(id);
        dispatch(showLoading());
        const response = await _getEmployeesProfileDetail(id);
        // console.log(response);

        if(response.status===200) {
            console.log(response.data);
            dispatch({
                type: GET_EMPLOYEE_PROFILE_DETAIL,
                payload: response.data,
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        console.error("Error in Getting Employee Detail", error);
        dispatch(hideLoading());
    }
};

export const updateEmployeeProfileDetail = (data, id) => async(dispatch) => {
    try {
        dispatch(showLoading());
        const response = await _updateEmployeeProfileDetail(data, id);

        if(response.status === 200) {
            dispatch({
                type: UPDATE_EMPLOYEE_PROFILE_DETAIL,
            });

            dispatch(setMessage("Employee Details Updated!"));
            dispatch(getEmployeesProfileDetail(id));
        }
        dispatch(hideLoading());
    } catch(error) {
        console.error("Update in Updating Employee Detail", error);
        dispatch(hideLoading());
    }
};

export const startInterview = (id, skills) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _startInterview(id, skills);

    if (response.status === 200) {
      dispatch({
        type: START_INTERVIEW_EMP_SUCCESS,
        payload: response.data,
      });

      dispatch(setMessage("Interview started successfully!"));
    } else {
      alert("Failed to start interview. Please try again.");
      window.location.reload();
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("Error in start interview", error);
    alert("Failed to start interview. Please try again.");
    window.location.reload();
    dispatch(hideLoading());
  }
};

export const submitAIanswer = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _submitAns(data);
    if (response.status === 200) {
      dispatch({
        type: SUBMIT_INTERVIEW_QUESTION_EMP_SUCCESS,
        payload: response.data,
      });
      dispatch(setMessage("Answer submitted successfully!"));
    } else {
      // dispatch({
      //   type: SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
      // });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in uploading cv in AI Interview", error);
    // dispatch({
    //   type: SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
    // });
  }
};

export const concludeInt = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _endInterview(data);
    if (response.status === 200) {
      dispatch({
        type: END_INTERVIEW_EMP_SUCCESS,
      });
      dispatch(setMessage("Interview concluded successfully!"));
    } else {
      dispatch(setMessage("There is some issue, please try again!"));
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(setMessage("There is some issue, please try again!"));
    console.error("error in uploading cv in AI Interview", error);
  }
};