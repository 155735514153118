import axios from "axios";

export const getAllOrgAdmins = async (page,size) => {
  try {
      const url = `/user/get_all_admin?page=${page}&size=${size}`;
      const response = await axios.get(url);
      return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllOrgOrgs = async (page,size) => {
  try {
      const url = `/user/get_all_org`;
      const response = await axios.get(url);
      return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllUsedOrgs = async (orgId) => {
  try {
      const url = `/user/get_all_used_by_orgs/${orgId}`;
      const response = await axios.get(url);
      return response.data;
  } catch (error) {
    throw error;
  }
};

